import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const fetchQuests = createAsyncThunk('quests/fetchQuests', async (_, { getState }) => {
  const { user } = getState();
  console.log('Fetching quests for user:', user.telegramId);
  const response = await fetch(`/api/quests?telegramId=${user.telegramId}`);
  if (!response.ok) {
    console.error('Failed to fetch quests. Status:', response.status);
    throw new Error('Failed to fetch quests');
  }
  const data = await response.json();
  console.log('Fetched quests:', data);
  return data;
});

export const completeQuest = createAsyncThunk(
  'quests/completeQuest',
  async ({ telegramId, questId }, { rejectWithValue }) => {
    console.log('Completing quest:', { telegramId, questId });
    try {
      const response = await fetch('/api/complete-quest', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ telegramId, questId }),
      });
      console.log('Complete quest response status:', response.status);
      if (!response.ok) {
        const errorData = await response.json();
        console.error('Failed to complete quest:', errorData);
        return rejectWithValue(errorData.message);
      }
      const data = await response.json();
      console.log('Quest completed successfully:', data);
      return data;
    } catch (error) {
      console.error('Error in completeQuest:', error);
      return rejectWithValue(error.message);
    }
  }
);

const questSlice = createSlice({
  name: 'quests',
  initialState: {
    quests: [],
    status: 'idle',
    error: null,
  },
  reducers: {
    updateQuestStatus: (state, action) => {
      const { questId, isCompleted } = action.payload;
      const quest = state.quests.find(q => q._id === questId);
      if (quest) {
        quest.isCompleted = isCompleted;
        console.log('Updated quest status:', { questId, isCompleted });
      } else {
        console.warn('Quest not found for status update:', questId);
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchQuests.pending, (state) => {
        state.status = 'loading';
        console.log('fetchQuests: pending');
      })
      .addCase(fetchQuests.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.quests = action.payload;
        console.log('fetchQuests: fulfilled', state.quests);
      })
      .addCase(fetchQuests.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        console.error('fetchQuests: rejected', action.error.message);
      })
      .addCase(completeQuest.pending, (state) => {
        state.status = 'loading';
        console.log('completeQuest: pending');
      })
      .addCase(completeQuest.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const quest = state.quests.find(q => q._id === action.payload.quest._id);
        if (quest) {
          quest.isCompleted = true;
          console.log('completeQuest: fulfilled, updated quest:', quest);
        } else {
          console.warn('completeQuest: fulfilled, but quest not found:', action.payload.quest._id);
        }
      })
      .addCase(completeQuest.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
        console.error('completeQuest: rejected', action.payload);
      });
  },
});

export const { updateQuestStatus } = questSlice.actions;
export default questSlice.reducer;