import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FaHome, FaStore, FaHouseUser, FaUsers, FaTasks } from 'react-icons/fa';

const MenuContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
  box-shadow: 0 -2px 5px rgba(0,0,0,0.1);
  z-index: 3;
`;

const MenuItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: ${props => props.active ? '#4CAF50' : '#333'};
  z-index: 1;
  cursor: pointer;
`;

const IconWrapper = styled.div`
  font-size: 24px;
  margin-bottom: 5px;
`;

const MenuText = styled.span`
  font-size: 12px;
`;

const BottomMenu = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const tg = window.Telegram?.WebApp;

  const handleClick = (path) => {
    if (tg && tg.HapticFeedback) {
      tg.HapticFeedback.impactOccurred('medium');
    }
    navigate(path);
  };

  return (
    <MenuContainer>
      <MenuItem 
        onClick={() => handleClick('/')} 
        active={location.pathname === '/'}
      >
        <IconWrapper><FaHome /></IconWrapper>
        <MenuText>Ферма</MenuText>
      </MenuItem>

      <MenuItem
        onClick={() => handleClick('/leaderboard')}
        active={location.pathname === '/leaderboard'}
      >
        <IconWrapper><FaUsers /></IconWrapper>
        <MenuText>Рейтинг</MenuText>
      </MenuItem>

      <MenuItem 
        onClick={() => handleClick('/shop')} 
        active={location.pathname === '/shop'}
      >
        <IconWrapper><FaStore /></IconWrapper>
        <MenuText>Магазин</MenuText>
      </MenuItem>

      <MenuItem 
        onClick={() => handleClick('/quests')} 
        active={location.pathname === '/quests'}
      >
        <IconWrapper><FaTasks /></IconWrapper>
        <MenuText>Задания</MenuText>
      </MenuItem>

      <MenuItem 
        onClick={() => handleClick('/deposit')} 
        active={location.pathname === '/deposit'}
      >
        <IconWrapper><FaHouseUser /></IconWrapper>
        <MenuText>Профиль</MenuText>
      </MenuItem>
    </MenuContainer>
  );
};

export default BottomMenu;