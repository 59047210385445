import { configureStore } from '@reduxjs/toolkit';
import farmReducer from './farmSlice';
import userReducer from './userSlice';
import questReducer from './questSlice';

export const store = configureStore({
  reducer: {
    farm: farmReducer,
    user: userReducer,
    quests: questReducer,
  },
});