import React from 'react';
import styled from 'styled-components';

const BackgroundContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`;

const AnimatedSVGBackground = () => (
  <BackgroundContainer>
    <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice">
      <defs>
        <linearGradient id="skyGradient" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" style={{ stopColor: '#87CEEB', stopOpacity: 1 }} />
          <stop offset="100%" style={{ stopColor: '#E0F6FF', stopOpacity: 1 }} />
        </linearGradient>
        <path id="leaf" d="M0,0 C-5,-5 -5,5 0,10 C5,5 5,-5 0,0" />
      </defs>

      {/* Sky */}
      <rect width="100%" height="100%" fill="url(#skyGradient)" />

      {/* Clouds */}
      <g>
        <path d="M50,20 Q60,10 70,20 T90,20 T110,20 T130,20 Q140,30 130,40 H70 Q60,50 50,40 Q40,30 50,20" fill="white" opacity="0.8">
          <animateTransform attributeName="transform" type="translate" from="-150,0" to="150%,0" dur="30s" repeatCount="indefinite" />
        </path>
        <path d="M200,50 Q210,40 220,50 T240,50 T260,50 Q270,60 260,70 H220 Q210,80 200,70 Q190,60 200,50" fill="white" opacity="0.6">
          <animateTransform attributeName="transform" type="translate" from="-250,0" to="150%,0" dur="25s" repeatCount="indefinite" />
        </path>
      </g>

      {/* Grass */}
      <rect x="0" y="80%" width="100%" height="20%" fill="#4a8f29" />

      {/* Trees */}
      <g>
        {/* Left side trees */}
        <path d="M10,80% L20,100% L0,100% Z" fill="#8B4513" />
        <path d="M-20,85% Q10,70% 40,85% T10,95% T-20,85%" fill="#FFA500" />
        
        <path d="M60,75% L70,100% L50,100% Z" fill="#8B4513" />
        <path d="M30,80% Q60,60% 90,80% T60,95% T30,80%" fill="#FF8C00" />
        
        {/* Right side trees */}
        <path d="M90%,78% L92%,100% L88%,100% Z" fill="#8B4513" />
        <path d="M85%,83% Q90%,68% 95%,83% T90%,93% T85%,83%" fill="#FFA500" />
        
        <path d="M95%,76% L97%,100% L93%,100% Z" fill="#8B4513" />
        <path d="M90%,81% Q95%,66% 100%,81% T95%,91% T90%,81%" fill="#FF8C00" />
      </g>

      {/* Falling Leaves */}
      {[...Array(20)].map((_, i) => (
        <use
          key={i}
          href="#leaf"
          fill={['#FF6347', '#FF4500', '#FFD700', '#FFA500'][i % 4]}
        >
          <animateTransform
            attributeName="transform"
            type="translate"
            from={`${Math.random() * 100}% 25%`}
            to={`${Math.random() * 100}% 100%`}
            dur={`${15 + Math.random() * 10}s`}
            begin={`-${Math.random() * 15}s`}
            repeatCount="indefinite"
          />
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="10 10 100"
            to="100 60 30"
            dur="12s"
            repeatCount="indefinite"
            additive="sum"
          />
          <animate
            attributeName="opacity"
            values="0;1;1;0"
            keyTimes="0;0.1;0.9;1"
            dur={`${15 + Math.random() * 10}s`}
            repeatCount="indefinite"
          />
        </use>
      ))}
      {[...Array(20)].map((_, i) => (
        <use
          key={i}
          href="#leaf"
          fill={['#FF6347', '#FF4500', '#FFD700', '#FFA500'][i % 4]}
        >
          <animateTransform
            attributeName="transform"
            type="translate"
            from={`${Math.random() * 100}% 25%`}
            to={`${Math.random() * 100}% 100%`}
            dur={`${15 + Math.random() * 10}s`}
            begin={`-${Math.random() * 15}s`}
            repeatCount="indefinite"
          />
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="10 50 100"
            to="90 60 180"
            dur="12s"
            repeatCount="indefinite"
            additive="sum"
          />
          <animate
            attributeName="opacity"
            values="0;1;1;0"
            keyTimes="0;0.1;0.9;1"
            dur={`${15 + Math.random() * 10}s`}
            repeatCount="indefinite"
          />
        </use>
      ))}
       {[...Array(20)].map((_, i) => (
        <use
          key={i}
          href="#leaf"
          fill={['#FF6347', '#FF4500', '#FFD700', '#FFA500'][i % 4]}
        >
          <animateTransform
            attributeName="transform"
            type="translate"
            from={`${Math.random() * 100}% 25%`}
            to={`${Math.random() * 100}% 100%`}
            dur={`${15 + Math.random() * 10}s`}
            begin={`-${Math.random() * 15}s`}
            repeatCount="indefinite"
          />
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="10 50 100"
            to="60 90 180"
            dur="12s"
            repeatCount="indefinite"
            additive="sum"
          />
          <animate
            attributeName="opacity"
            values="0;1;1;0"
            keyTimes="0;0.1;0.9;1"
            dur={`${15 + Math.random() * 10}s`}
            repeatCount="indefinite"
          />
        </use>
      ))}
    </svg>
  </BackgroundContainer>
);

export default AnimatedSVGBackground;