import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import io from 'socket.io-client';
import styled, { createGlobalStyle, keyframes } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { TonConnectButton, useTonAddress, useTonConnectUI } from '@tonconnect/ui-react';
import { fetchFarm, plantCrop, harvestCrop, buyPlot, selectSeed, updateFarmState } from '../farmSlice';
import { updateUser, setBlockedStatus } from '../userSlice';
import Toast from './Toast';
import AnimatedSVGBackground from './AnimatedSVGBackground';
import { useAdsgram } from '../hooks/useAdsgram';
import GiftBox from '../assets/gift-box.png';
import InfoIcon from '../assets/information.png';
import SeedMakerPic from '../assets/seed-maker.png';
import Dirt from '../assets/dirt.svg';
import NonAvatar from '../assets/nonavatarfarmer.jpeg';

const socket = io('https://crptchrd.online', {
  path: '/socket.io/',
  transports: ['websocket', 'polling'],
  reconnectionAttempts: 5,
  reconnectionDelay: 1000
});

socket.on('connect_error', (error) => {
  console.error('Socket connection error:', error);
});

socket.on('connect', () => {
  console.log('Socket connected successfully');
});

const tg = window.Telegram?.WebApp;

const glowAnimation = keyframes`
  0% { text-shadow: 0 0 5px #ffd700; }
  50% { text-shadow: 0 0 20px #ffd700, 0 0 30px #ffd700; }
  100% { text-shadow: 0 0 5px #ffd700; }
`;

export const GlobalStyle = createGlobalStyle`
  @keyframes floatUp {
  0% {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateY(-50px) scale(1.2);
    opacity: 0;
  }
}

  .floating-element {
    position: absolute;
    animation: floatUp 1.5s ease-out forwards;
    pointer-events: none;
    font-size: 24px;
  }

  html, body {
    touch-action: manipulation;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
`;

const FarmContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  min-height: 100vh;
  position: relative;
  z-index: 1;
`;

const USDTIcon = styled.img`
  width: 15px;
  height: 15px;
  vertical-align: text-bottom;
`;

const SeedMaker = styled.div`
  width: 350px;
  height: 350px;
  position: absolute;
  top: 40%;
  padding: 0;
  background: url(${SeedMakerPic}) no-repeat center center;
  background-size: contain;
  border: none;
`;

const BalanceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: ${props => props.isBonusAvailable ? 'pointer' : 'default'};
`;

const Balance = styled.span`
  font-family: 'Rocher';
  font-size: 40px;
  display: flex;
  align-items: center;
  animation: ${props => props.isBonusAvailable ? glowAnimation : 'none'} 2s infinite;
`;

const BonusInfo = styled.div`
  font-family: "Rubik Spray Paint", system-ui;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  text-align: center;
`;

const Board = styled.div`
  position: sticky;
  top: 1em;
  width: 50vw;
  height: 50vw;
  min-width: 320px;
  min-height: 320px;
  max-width: calc(100vh - 2em);
  max-height: calc(100vh - 2em);
  overflow: hidden;
  border: 12px solid #6D5720;
  border-radius: 12px;
  background-color: #4a3000;
`;

const Garden = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(5, 1fr);
  gap: 4px; // Добавляем промежутки между грядками
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 4px; // Добавляем отступ от края доски
  box-sizing: border-box;
`;

const Plot = styled.div`
  width: 100%;
  height: 100%;
  background-color: #836B32;
  background-image: url(${Dirt});
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 4px; // Скругляем углы для эстетики
  position: relative;
  overflow: hidden;
  ${props => {
    if (props.type === 'fast') {
      return `box-shadow: 0 0 10px 3px #CC33FF;`;
    } else if (props.type === 'lucky') {
      return `box-shadow: 0 0 10px 3px #FFFF00;`;
    }
    return '';
  }}
`;

const Plant = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Jost", sans-serif;
  filter: drop-shadow(2px 2px 2px rgba(0,0,0,0.5));
  font-size: 13px;
`;

const CropIcon = styled.span`
  font-size: 24px;
  filter: drop-shadow(2px 2px 2px rgba(0,0,0,0.5));
`;

const Button = styled.button`
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
  &:hover {
    background-color: #45a049;
  }
`;

const StatusBar = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px
`;

const BonusButton = styled.button`
  width: 50px; /* Устанавливаем ширину кнопки */
  height: 50px; /* Устанавливаем высоту кнопки */
  padding: 0; /* Убираем внутренние отступы */
  background: url(${GiftBox}) no-repeat center center; /* Устанавливаем фоновое изображение */
  background-size: contain; /* Подгоняем изображение под размер кнопки */
  border: none; /* Убираем границу */
  cursor: pointer; /* Добавляем курсор для интерактивности */
  margin-bottom: 10px; /* Оставляем нижний отступ */
`;

const InfoButton = styled.button`
  width: 20px;
  height: 20px;
  padding: 0;
  background: url(${InfoIcon}) no-repeat center center;
  background-size: contain;
  border: none;
  margin-left: 5px;
`;

const InfoModal = styled.div`
  position: fixed;
  font-family: "Jost", sans-serif;
  font-style: extralight;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  z-index: 1000;
  box-shadow: 0 0 10px rgba(0,0,0,0.3);
  max-width: 80%;
  width: 400px;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
`;

const MessageOverlay = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 20px;
  border-radius: 10px;
  z-index: 1000;
`;

const NewPlotButton = styled(Plot)`
  background-color: #4CAF50;
  color: white;
  font-size: 24px;
  &:hover {
    background-color: #45a049;
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 5px;
`;

const InventoryModal = styled.div`
  width: 300px;
  height: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  z-index: 1000;
  box-shadow: 0 0 10px rgba(0,0,0,0.3);
`;

const SeedItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  position: relative;
  width: 80px;
  height: 120px;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const SeedIcon = styled.span`
  font-size: 24px;
`;

const SeedName = styled.span`
  font-size: 12px;
  text-align: center;
  margin-top: 5px;
`;

const SeedCount = styled.span`
  position: absolute;
  bottom: 5px;
  right: 5px;
  font-size: 12px;
  font-weight: bold;
`;

const InventoryGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  justify-items: center;
  margin-bottom: 20px;
`;

const EmptyInventoryMessage = styled.p`
  text-align: center;
  color: #666;
`;

const ShopLink = styled.span`
  color: blue;
  text-decoration: underline;
  cursor: pointer;
`;

const PlayerInfoContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  position: relative;
`;

const PlayerPhoto = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-right: 15px;
`;

const PlayerDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const PlayerName = styled.h2`
  font-family: 'Rocher';
  margin: 0;
  font-size: 1.2em;
`;

const PlayerTag = styled.span`
  font-size: 0.4em;
  opacity: 0.7;
`;

const PlayerLevel = styled.div`
  font-family: 'Rocher';
  position: absolute;
  bottom: 30px;
  left: 95px;
  font-size: 1.9em;
  font-weight: bold;
  color: #ffd700;
  text-shadow: 0 0 5px rgba(255, 215, 0, 0.5);
`;

const PlayerTitle = styled.div`
  font-style: italic;
  color: #666;
  margin-top: 5px;
`;

const ExperienceBar = styled.div`
  width: 200px;
  height: 20px;
  background-color: #ddd;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 5px;
  position: relative;
`;

const ExperienceProgress = styled.div`
  width: ${props => props.progress}%;
  height: 100%;
  background-color: #ffd700;
  transition: width 0.5s ease-in-out;
`;

const ExperienceText = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  font-weight: bold;
  text-shadow: 0 0 2px rgba(255, 255, 255, 0.7);
`;

const ConfirmModal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0,0,0,0.3);
  z-index: 1000;
  text-align: center;
`;

const SwitchButton = styled.button`
  position: fixed;
  right: ${props => props.direction === 'right' ? '10px' : 'auto'};
  left: ${props => props.direction === 'left' ? '10px' : 'auto'};
  top: 85%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 1000;
`;

const ArrowIcon = styled.span`
  font-size: 24px;
`;

const ButtonText = styled.span`
  font-size: 12px;
  color: black;
`;

const Farm = () => {
  const dispatch = useDispatch();
  const { balance, crops, size, maxSize, inventory, status, error, lastBonusTime, plotPrices } = useSelector(state => state.farm);
  const { level, experience, telegramId, isBlocked, title, customTitle, username, photoUrl } = useSelector(state => state.user);
  console.log('TelegramId in Farm component:', telegramId);
  const farm = useSelector(state => state.farm);
  const [toasts, setToasts] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [currentCropType, setCurrentCropType] = useState(null);
  const [longPressTimer, setLongPressTimer] = useState(null);
  const [plantingIndex, setPlantingIndex] = useState(null);
  const [currentLocation, setCurrentLocation] = useState('farm');

  const navigate = useNavigate();

  // Добавляем новые состояния
  const [localInventory, setLocalInventory] = useState(inventory);
  const [localCrops, setLocalCrops] = useState(crops);

  const userFriendlyAddress = useTonAddress();
  const [tonConnectUI] = useTonConnectUI();

  const [showTutorial, setShowTutorial] = useState(() => {
    return localStorage.getItem('tutorialCompleted') !== 'true';
  });
  const [message, setMessage] = useState(null);
  const [showBuyModal, setShowBuyModal] = useState(false);
  const [showInventory, setShowInventory] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [selectedPlotIndex, setSelectedPlotIndex] = useState(null);
  const { selectedSeed } = useSelector(state => state.farm);

  // Проверка на блокировку после получения данных
  useEffect(() => {
    console.log('Current isBlocked value:', isBlocked);
    if (Boolean(isBlocked)) {
      console.log("Пользователь заблокирован, перенаправление на /banned");
      navigate('/banned');
    }
  }, [isBlocked, navigate]);

  // Загружаем данные фермы при монтировании компонента
  useEffect(() => {
    dispatch(fetchFarm());
  }, [dispatch]);

  // Загружаем данные пользователя при монтировании компонента
  useEffect(() => {
    const fetchData = async () => {
      try {
        const savedInitData = localStorage.getItem('telegramInitData');
        if (savedInitData) {
          const result = await dispatch(fetchFarm(savedInitData)).unwrap();
          console.log("Данные фермы успешно получены: ", result);
        }
      } catch (error) {
        console.error("Ошибка при загрузке фермы: ", error);
      }
    };
    fetchData();
  }, [dispatch]);

  const getExperienceProgress = () => {
    const experienceTable = [
      { level: 1, xpNeeded: 0 },
      { level: 2, xpNeeded: 100 },
      { level: 3, xpNeeded: 500 },
      { level: 4, xpNeeded: 1500 },
      { level: 5, xpNeeded: 3000 },
      { level: 6, xpNeeded: 6000 },
      { level: 7, xpNeeded: 10000 },
      { level: 8, xpNeeded: 15000 },
      { level: 9, xpNeeded: 21000 },
      { level: 10, xpNeeded: 28000 },
    ];

    const currentLevelExp = experienceTable.find(exp => exp.level === level)?.xpNeeded || 0;
    const nextLevelExp = experienceTable.find(exp => exp.level === level + 1)?.xpNeeded || experience;
    const progressExp = experience - currentLevelExp;
    const totalExpNeeded = nextLevelExp - currentLevelExp;

    return (progressExp / totalExpNeeded) * 100;
  };

  const switchLocation = () => {
    setCurrentLocation(currentLocation === 'farm' ? 'fermenter' : 'farm');
  };

  const Fermenter = () => (
    <div>
      <h2>Экстрактор семян</h2>
      <p>Экстрактор семян — это оборудование для производства семян из выращенных на ферме и собранных культур. На производство различных семян уходит разное время.<br/><br/>На данный момент <b>Экстрактор</b> в разработке.</p>
      <br/>
      <SeedMaker></SeedMaker>
    </div>
  );

  const tonRate = 462.15 / 100; // TON to USD rate

  const Timer = React.memo(({ harvestAt }) => {
    const [timeRemaining, setTimeRemaining] = useState(() => getTimeRemaining(harvestAt));
  
    useEffect(() => {
      const timer = setInterval(() => {
        setTimeRemaining(getTimeRemaining(harvestAt));
      }, 1000);
      return () => clearInterval(timer);
    }, [harvestAt]);
  
    return <div>{timeRemaining}</div>;
  });

  const showToast = (message, type = 'info', duration = 3000) => {
    const id = Date.now();
    setToasts(prevToasts => [...prevToasts, { id, message, type }]);
    setTimeout(() => removeToast(id), duration);
  };

  const removeToast = (id) => {
    setToasts(prevToasts => prevToasts.filter(toast => toast.id !== id));
  };

  useEffect(() => {
    console.log('Farm state updated:', farm);
  }, [farm]);

  useEffect(() => {
    setLocalCrops(farm.crops);
    setLocalInventory(farm.inventory);
  }, [farm.crops, farm.inventory]);


  useEffect(() => {
  const fetchData = async () => {
    try {
      const savedInitData = localStorage.getItem('telegramInitData');
      if (savedInitData) {
        await dispatch(fetchFarm(savedInitData)).unwrap();
      }
    } catch (error) {
      console.error('Fetch farm error:', error);
    }
  };
  fetchData();
}, [dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const savedInitData = localStorage.getItem('telegramInitData');
        if (savedInitData) {
          const result = await dispatch(fetchFarm(savedInitData)).unwrap();
          dispatch(updateUser({ level: result.level, experience: result.experience }));
        }
      } catch (error) {
        console.error('Fetch farm error:', error);
      }
    };
    fetchData();

    const socket = io(process.env.REACT_APP_API_URL || 'https://crptchrd.online', {
      path: '/socket.io/',
      transports: ['websocket', 'polling']
    });
    if (telegramId) {
      socket.emit('join', telegramId);
    }
    socket.on('farmUpdate', (updatedFarm) => {
      console.log('Received farm update:', updatedFarm);
      dispatch({ type: 'farm/updateFarm', payload: updatedFarm });
      if (updatedFarm.level !== undefined && updatedFarm.experience !== undefined) {
        dispatch(updateUser({ level: updatedFarm.level, experience: updatedFarm.experience }));
      }
    });
    return () => {
      socket.disconnect();
    };
  }, [dispatch, telegramId]);
  
  useEffect(() => {
    if (telegramId) {
      window.ADSGRAM_USER_ID = telegramId;
    }
  }, [telegramId]);

  useEffect(() => {
    console.log('Состояние пользователя обновлено:', { level, experience, isBlocked });
  }, [level, experience, isBlocked]);

  const completeTutorial = () => {
    setShowTutorial(false);
    localStorage.setItem('tutorialCompleted', 'true');
  };

  const handleAdReward = useCallback((token) => {
    console.log('Ad reward callback triggered');
    // Отправляем запрос на сервер для получения бонуса
    fetch(`/api/reward?token=${token}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        console.log('Received response from server:', response);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        console.log('Parsed response data:', data);
        // Обновляем состояние фермы с полученными данными
        dispatch({ type: 'farm/updateFarm', payload: data });
        showToast("Bonus received successfully!", "success", 3000);
      })
      .catch(error => {
        console.error('Error getting bonus:', error);
        showToast("Error receiving bonus. Please try again.", "error", 3000);
      });
  }, [dispatch, showToast]);

  const handleAdError = useCallback((result) => {
    console.error('Ad error:', result);
    showToast("Реклама пока недоступна. Попробуйте позднее.", "error", 3000);
  }, [showToast]);

  if (status === 'loading') return <div>Loading...</div>;
  if (status === 'failed') return <div>Error: {error}</div>;
  if (!crops) return <div>No farm data available</div>;

  

  const handlePlotClick = (index) => {
    const crop = localCrops[index];
    if (crop) {
      if (new Date() >= new Date(crop.harvestAt)) {
        handleHarvest(index);
      }
    } else {
      setPlantingIndex(index);
      setShowInventory(true);
    }
  };

  const handleSeedSelection = (seedType) => {
    if (plantingIndex !== null && inventory[seedType] > 0) {
      setCurrentCropType(seedType);
      if (farm.size > 10) {
        setShowConfirmModal(true);
      } else {
        plantSingleCrop(plantingIndex, seedType);
      }
    }
    setShowInventory(false);
  };

  const handleInfoClick = () => {
    setShowInfoModal(true);
};

const renderInventory = () => {
  const hasSeeds = Object.values(localInventory).some(amount => amount > 0);

  return (
    <InventoryModal>
      <h3>Инвентарь</h3>
      {hasSeeds ? (
        <InventoryGrid>
          {Object.entries(inventory).map(([seedType, amount]) => {
            if (amount > 0) {
              return (
                <SeedItem 
                  key={seedType}
                  onTouchStart={() => handleSeedTouchStart(seedType)}
                  onTouchEnd={handleSeedTouchEnd}
                  onClick={() => handleSeedClick(seedType)}
                >
                  <SeedIcon>{getCropEmoji(seedType)}</SeedIcon>
                  <SeedName>{seedType}</SeedName>
                  <SeedCount>{amount}</SeedCount>
                </SeedItem>
              );
            }
            return null;
          })}
        </InventoryGrid>
      ) : (
        <EmptyInventoryMessage>
          Инвентарь пуст. <ShopLink onClick={() => navigate('/shop')}>Посетите магазин</ShopLink>, чтобы купить что-нибудь
        </EmptyInventoryMessage>
      )}
      <Button onClick={() => setShowInventory(false)}>Закрыть</Button>
    </InventoryModal>
  );
};

  const renderInfoModal = () => (
    <InfoModal>
        <CloseButton onClick={() => setShowInfoModal(false)}>×</CloseButton>
        <p>Привет! <br/>Ты можешь вывести свои заработанные монеты в реальные.<br/> <ShopLink onClick={() => navigate('/deposit')}>На странице профиля</ShopLink> есть подсчет средств, загляни туда.</p><br/>
        <p>У нас есть свои ограничения: вывод доступен по достижению баланса в 10$.<br/>Раз в 24ч. можно вывести до 100$</p><br/>
        <p><center>Привяжи свой кошелёк: </center><center><TonConnectButton /></center></p><br/>
        <p>Заявки на вывод обрабатываются в автоматическом режиме.<br/>Отправка заработанных монет осуществляется в течение 1-3 часов.<br/></p>
    </InfoModal>
);

const plantSingleCrop = async (index, cropType) => {
  if (farm.inventory[cropType] > 0) {
    try {
      const result = await dispatch(plantCrop({ index, cropType, telegramId })).unwrap();
      console.log('Посадка успешна:', result);
      dispatch(updateFarmState(result));
      showToast(`Посажено ${cropType}`, "success", 3000);
      
      if (tg && tg.HapticFeedback) {
        tg.HapticFeedback.impactOccurred('light');
      }
    } catch (error) {
      console.error('Ошибка при посадке:', error);
      showToast(`Ошибка: ${error.message}`, "error", 3000);
    }
  } else {
    showToast("Нет доступных семян", "error", 3000);
  }
};

const handleSeedTouchStart = (seedType) => {
  setLongPressTimer(setTimeout(() => {
    const emptyPlots = farm.crops.filter((crop, index) => !crop && index < farm.size).length;
    const availableSeeds = inventory[seedType];
    
    console.log('Empty plots:', emptyPlots);
    console.log('Available seeds:', availableSeeds);
    console.log('Farm size:', farm.size);
    console.log('Farm crops:', farm.crops);
    
    if (emptyPlots > 0 && availableSeeds > 0) {
      setCurrentCropType(seedType);
      setShowInventory(false);
      setShowConfirmModal(true);
    } else if (emptyPlots === 0) {
      showToast("Нет свободных грядок", "warning", 3000);
    } else if (availableSeeds === 0) {
      showToast("Нет доступных семян", "warning", 3000);
    }
  }, 500));
};

  const handleSeedTouchEnd = () => {
    if (longPressTimer) {
      clearTimeout(longPressTimer);
    }
  };

  const handleSeedClick = (seedType) => {
    if (longPressTimer) {
      clearTimeout(longPressTimer);
    }
    plantSingleCrop(plantingIndex, seedType);
    setShowInventory(false);
  };

  const plantAllAvailable = async (cropType) => {
    const emptyPlots = farm.crops.reduce((acc, crop, index) => {
      if (!crop && index < farm.size) acc.push(index);
      return acc;
    }, []);
  
    console.log('Empty plots before planting:', emptyPlots);
  
    let seedsToPlant = inventory[cropType];
    let seedsPlanted = 0;
  
    for (const plotIndex of emptyPlots) {
      if (seedsToPlant > 0) {
        try {
          const result = await dispatch(plantCrop({ index: plotIndex, cropType, telegramId })).unwrap();
          seedsPlanted++;
          seedsToPlant--;
          dispatch(updateFarmState(result));
        } catch (error) {
          console.error('Ошибка при посадке:', error);
          break;
        }
      } else {
        break;
      }
    }
  
    console.log('Seeds planted:', seedsPlanted);
  
    if (seedsPlanted > 0) {
      showToast(`Засажено ${seedsPlanted} грядок ${cropType}`, "success", 3000);
    } else {
      showToast("Не удалось засадить грядки", "error", 3000);
    }
    
    setShowConfirmModal(false);
  };

  const renderConfirmModal = () => (
    <ConfirmModal>
      <h3>Засадить все грядки {currentCropType}?</h3>
      <Button onClick={() => plantAllAvailable(currentCropType)}>Да</Button>
      <Button onClick={() => setShowConfirmModal(false)}>Нет</Button>
    </ConfirmModal>
  );

const handleBuyPlot = async (index, price) => {
  if (index < 10) {
    // Покупка за игровую валюту
    if (farm.balance >= price) {
      dispatch(buyPlot({ telegramId, index }))
        .unwrap()
        .then(() => {
          showToast("New plot purchased!", "success", 3000);
        })
        .catch((error) => {
          showToast(`Error: ${error}`, "error", 3000);
        });
    } else {
      showToast("Not enough coins!", "error", 3000);
    }
  } else {
    // Покупка за реальные деньги
    if (!userFriendlyAddress) {
      showToast("Please connect your wallet first", "warning", 3000);
      return;
    }

    const usdPrice = farm.realPlotPrices[index] / 100; // Цена уже в центах, переводим в доллары
    const tonAmount = (usdPrice / tonRate).toFixed(9);
    const nanotons = Math.floor(parseFloat(tonAmount) * 1e9).toString();

    const transaction = {
      validUntil: Math.floor(Date.now() / 1000) + 60 * 20,
      messages: [
        {
          address: "UQCLsrPlcX_eoe-hT9QMtpik3D54bVKD0DbmaiGXf7cYKDfv",
          amount: nanotons
        }
      ]
    };

    try {
      showToast("Sending transaction...", "info", 3000);
      const result = await tonConnectUI.sendTransaction(transaction);
      console.log("Transaction result:", result);
      
      // Добавляем задержку в 5 секунд
      await new Promise(resolve => setTimeout(resolve, 5000));

      const response = await fetch('/api/buy-real-plot', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          telegramId,
          plotIndex: index,
          transactionId: result.boc
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to purchase plot');
      }

      const updatedFarm = await response.json();
      dispatch(updateFarmState(updatedFarm));
      showToast(`New plot purchased successfully!`, "success", 3000);
    } catch (error) {
      console.error("Error in plot purchase:", error);
      showToast(`Error: ${error.message}. Please try again later.`, "error", 3000);
    } finally {
      setTimeout(() => setMessage(null), 5000);
    }
  }
};

const handleHarvest = (index) => {
  dispatch(harvestCrop({ index, telegramId }))
    .unwrap()
    .then((result) => {
      console.log('Harvest result:', result);
      if (result) {
        dispatch({ type: 'farm/updateFarm', payload: result });
        dispatch({ type: 'user/updateUser', payload: { level: result.level, experience: result.experience } });
        
        const plotElement = document.querySelector(`[data-plot-index="${index}"]`);
        if (plotElement) {
          const rect = plotElement.getBoundingClientRect();
          const x = rect.left + rect.width / 2;
          const y = rect.top + rect.height / 2;

          const fragment = document.createDocumentFragment();
          const animationContainer = document.createElement('div');
          animationContainer.style.cssText = `
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
            pointer-events: none;
            z-index: 1000;
          `;

          const createAnimatedElement = (content, delay) => {
            const element = document.createElement('div');
            element.textContent = content;
            element.style.cssText = `
              position: absolute;
              left: ${x + (Math.random() * 40 - 20)}px;
              top: ${y}px;
              font-size: 24px;
              animation: floatUp 1.5s ease-out ${delay}s forwards;
            `;
            return element;
          };

          // Создаем монеты
          for (let i = 0; i < Math.min(result.harvestResult.value, 5); i++) {
            animationContainer.appendChild(createAnimatedElement('🪙', Math.random() * 0.5));
          }

          // Создаем XP
          animationContainer.appendChild(createAnimatedElement(`+${result.harvestResult.xp}⭐`, 0.2));

          // Создаем семена, если они выпали
          if (result.harvestResult.seedDropped) {
            animationContainer.appendChild(createAnimatedElement(getCropEmoji(result.harvestResult.seedDropped), 0.3));
          }

          fragment.appendChild(animationContainer);
          document.body.appendChild(fragment);

          // Удаляем контейнер после завершения анимации
          setTimeout(() => {
            document.body.removeChild(animationContainer);
          }, 2000);
        }
        
        if (tg && tg.HapticFeedback) {
          tg.HapticFeedback.impactOccurred('medium');
        }
      }
    })
    .catch((error) => {
      console.error('Harvest error:', error);
      setMessage(`Error: ${error}`);
      setTimeout(() => setMessage(null), 2000);
    });
};

  const getCropIcon = (cropType) => {
    switch(cropType) {
      case 'wheat': return '🌾';
      case 'corn': return '🌽';
      case 'potato': return '🥔';
      case 'carrot' : return '🥕';
      case 'tomato' : return '🍅';
      case 'pumpkin' : return '🎃';
      default: return '';
    }
  };

  const getCropEmoji = (cropType) => {
    switch(cropType) {
      case 'wheat': return '🌾';
      case 'corn': return '🌽';
      case 'potato': return '🥔';
      case 'carrot' : return '🥕';
      case 'tomato' : return '🍅';
      case 'pumpkin' : return '🎃';
      default: return '';
    }
  };

  const renderGarden = () => (
    <Board>
      <Garden>
        {Array(25).fill().map((_, index) => {
          const crop = farm.crops[index];
          const isGrown = crop && new Date() >= new Date(crop.harvestAt);
          const isAvailable = index < farm.size;
          const isNextPlot = index === farm.size;
          const price = index < 10 ? farm.plotPrices[index] : farm.realPlotPrices[index];
  
          if (isAvailable) {
            console.log(`Plot ${index} type:`, farm.plotTypes[index]);
            return (
              <Plot 
                key={index} 
                data-plot-index={index} 
                onClick={() => handlePlotClick(index)}
                type={farm.plotTypes[index]}
              >
                {crop ? (
                  <Plant>
                    <CropIcon>{getCropIcon(crop.type)}</CropIcon>
                    {!isGrown && <Timer harvestAt={crop.harvestAt} />}
                  </Plant>
                ) : (
                  <span>+</span>
                )}
              </Plot>
            );
          } else if (isNextPlot || index < farm.purchasedPlots) {
            return (
              <Plot 
                  key={index} 
                  onClick={() => handleBuyPlot(index, price)}
                  type={farm.plotTypes[index]}
                  >
                {index < 10 ? (
                  <span style={{ fontSize: '12px' }}>🪙{price}</span>
                ) : (
                  <span style={{ fontSize: '15px' }}>
                    ${(price / 100)}
                  </span>
                )}
              </Plot>
            );
          } else {
            return (
              <Plot 
                key={index} 
                style={{ opacity: 0.5, cursor: 'not-allowed' }}
                type={farm.plotTypes[index]}
                >
                {index < 10 ? (
                  <span style={{ fontSize: '12px' }}>🪙{price}</span>
                ) : (
                  <span style={{ fontSize: '15px' }}>
                    ${(price / 100)}
                  </span>
                )}
              </Plot>
            );
          }
        })}
      </Garden>
    </Board>
  );

  const getTimeRemaining = (harvestAt) => {
    if (!harvestAt) return "N/A";
    const remaining = new Date(harvestAt).getTime() - Date.now();
    if (remaining <= 0) return "Ready!";
    
    const hours = Math.floor(remaining / 3600000);
    const minutes = Math.floor((remaining % 3600000) / 60000);
    const seconds = Math.floor((remaining % 60000) / 1000);
  
    if (hours > 0) {
      return `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    } else {
      return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }
  };


  return (
    <>
    <GlobalStyle />
    <AnimatedSVGBackground />
    <FarmContainer>
    {currentLocation === 'farm' ? (
      <>
       <PlayerInfoContainer>
        <PlayerPhoto src={photoUrl || `${NonAvatar}` } alt="Player" />
        <PlayerDetails>
        <PlayerName>
            {username ? (
              <>
                {username}
                <PlayerTag>#{telegramId}</PlayerTag>
              </>
            ) : (
              <>
                User
                <PlayerTag>#{telegramId}</PlayerTag>
              </>
            )}
          </PlayerName>
          <ExperienceBar>
            <ExperienceProgress progress={getExperienceProgress()} />
            <ExperienceText>{experience}</ExperienceText>
          </ExperienceBar>
          <PlayerTitle>{customTitle || title || 'Загрузка...'}</PlayerTitle>
        </PlayerDetails>
        <PlayerLevel>{level}</PlayerLevel>
      </PlayerInfoContainer> 
      <StatusBar>
      <Balance>
        🪙{farm.balance}
        <InfoButton onClick={handleInfoClick}></InfoButton>
      </Balance>
    </StatusBar>
      {showInfoModal && renderInfoModal()}     
      {renderGarden()}
      {showConfirmModal && renderConfirmModal()}
      {showInventory && renderInventory()}       
        {showBuyModal && (
        <ModalOverlay>
            <ModalContent>
            <h2>Buy New Plot</h2>
            <p>Cost: 🪙{farm.plotPrices && farm.plotPrices[farm.size] ? farm.plotPrices[farm.size] : 'N/A'}</p>
            <Button onClick={handleBuyPlot}>Confirm Purchase</Button>
            <Button onClick={() => setShowBuyModal(false)}>Cancel</Button>
            </ModalContent>
        </ModalOverlay>
        )}
        <SwitchButton direction="right" onClick={switchLocation}>
            <ArrowIcon>➡️</ArrowIcon>
            <ButtonText>Экстрактор семян</ButtonText>
          </SwitchButton>
        </>
      ) : (
        <>
          <Fermenter />
          <SwitchButton direction="left" onClick={switchLocation}>
            <ArrowIcon>⬅️</ArrowIcon>
            <ButtonText>Ферма</ButtonText>
          </SwitchButton>
        </>
      )}
      {message && <MessageOverlay>{message}</MessageOverlay>}

      {selectedSeed && (
            <div 
              style={{
                position: 'fixed', 
                pointerEvents: 'none',
                left: 0,
                top: 0,
                fontSize: '24px'
              }}
            >
              {getCropEmoji(selectedSeed)}
            </div>
          )}
          {showInventory && renderInventory()}
          {toasts.map(toast => (
          <Toast
            key={toast.id}
            message={toast.message}
            onClose={() => removeToast(toast.id)}
          />
        ))}
    </FarmContainer>
    </>
  );
};

export default Farm;