import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { fetchQuests, completeQuest } from '../questSlice';
import Toast from './Toast';

const QuestsContainer = styled.div`
  padding: 20px;
  background-color: #f0f8ff;
  min-height: 100vh;
`;

const QuestItem = styled.div`
  position: relative;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;
`;

const QuestTitle = styled.h3`
  margin-top: 0;
`;

const QuestLink = styled.a`
  color: #4CAF50;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const QuestDescription = styled.p`
  color: #666;
`;

const QuestReward = styled.div`
  font-weight: bold;
  color: #4CAF50;
`;

const CompleteButton = styled.button`
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  &:disabled {
    background-color: #cccccc;
  }
`;

const CompletedOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
`;

const LoadingSpinner = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  margin: 20px auto;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const ErrorMessage = styled.div`
  color: red;
  text-align: center;
  margin: 20px 0;
`;

const QuestsPage = () => {
  const dispatch = useDispatch();
  const { quests, status, error } = useSelector(state => state.quests);
  const { telegramId } = useSelector(state => state.user);
  const [toasts, setToasts] = useState([]);

  useEffect(() => {
    dispatch(fetchQuests());
  }, [dispatch]);

  const showToast = (message, type = 'info', duration = 3000) => {
    const id = Date.now();
    setToasts(prevToasts => [...prevToasts, { id, message, type }]);
    setTimeout(() => removeToast(id), duration);
  };

  const removeToast = (id) => {
    setToasts(prevToasts => prevToasts.filter(toast => toast.id !== id));
  };

  const handleCompleteQuest = async (questId) => {
    try {
      const resultAction = await dispatch(completeQuest({ telegramId, questId }));
      
      if (completeQuest.fulfilled.match(resultAction)) {
        const { message, quest } = resultAction.payload;
        let rewardMessage = 'Задание успешно выполнено. Награда: ';
        if (quest.reward.coins) rewardMessage += `+🪙${quest.reward.coins} монет, `;
        if (quest.reward.experience) rewardMessage += `+⭐${quest.reward.experience} опыта`;
        if (quest.reward.seeds && Object.keys(quest.reward.seeds).length > 0) {
          rewardMessage += ', +';
          Object.entries(quest.reward.seeds).forEach(([seedType, amount]) => {
            rewardMessage += `${amount} ${seedType}, `;
          });
          rewardMessage = rewardMessage.slice(0, -2);
        }
        showToast(rewardMessage, 'success', 5000);
      } else if (completeQuest.rejected.match(resultAction)) {
        const errorMessage = resultAction.payload || 'Произошла ошибка при выполнении задания';
        showToast(errorMessage, 'error', 3000);
      }
    } catch (error) {
      showToast('Произошла ошибка при выполнении задания', 'error', 3000);
    }
  };

  return (
    <QuestsContainer>
      <h2>Доступные задания</h2>
      {status === 'loading' && <LoadingSpinner />}
      {status === 'failed' && <ErrorMessage>Ошибка: {error}</ErrorMessage>}
      {status === 'succeeded' && quests.map(quest => (
        <QuestItem key={quest._id}>
          <QuestTitle>{quest.title}</QuestTitle>
          <QuestDescription>{quest.description}</QuestDescription>
          {quest.channelLink && (
            <QuestLink href={quest.channelLink} target="_blank" rel="noopener noreferrer">
              Перейти на канал
            </QuestLink>
          )}
          <QuestReward>
            Награда: {quest.reward.coins} монет, {quest.reward.experience} опыта
            {quest.reward.seeds && Object.entries(quest.reward.seeds).length > 0 && (
              <div>
                Семена:
                {Object.entries(quest.reward.seeds).map(([seedType, amount]) => (
                  <div key={seedType}>{amount} {seedType}</div>
                ))}
              </div>
            )}
          </QuestReward>
          {quest.isCompleted ? (
            <CompletedOverlay>Выполнено</CompletedOverlay>
          ) : (
            <CompleteButton
              onClick={() => handleCompleteQuest(quest._id)}
              disabled={status === 'loading'}
            >
              {status === 'loading' ? 'Выполнение...' : 'Выполнить задание'}
            </CompleteButton>
          )}
        </QuestItem>
      ))}
      {toasts.map(toast => (
        <Toast
          key={toast.id}
          message={toast.message}
          type={toast.type}
          onClose={() => removeToast(toast.id)}
        />
      ))}
    </QuestsContainer>
  );
};

export default QuestsPage;