import React from 'react';
import styled from 'styled-components';
import { QRCodeSVG } from 'qrcode.react';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(45deg, #1a2a6c, #b21f1f, #fdbb2d);
  color: white;
  text-align: center;
  padding: 20px;
`;

const QRCodeContainer = styled.div`
  background: white;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
`;

const Message = styled.p`
  font-size: 18px;
  max-width: 300px;
`;

const UnsupportedPlatform = () => {
  const appUrl = "https://t.me/crptfarmbot/play"; // Замените на URL вашего Telegram бота

  return (
    <Container>
      <QRCodeContainer>
        <QRCodeSVG value={appUrl} size={200} />
      </QRCodeContainer>
      <Message>
        CryptoFarm доступно только через iOS и Android версии Telegram. Отсканируйте QR-код, чтобы открыть приложение на вашем мобильном устройстве.
      </Message>
    </Container>
  );
};

export default UnsupportedPlatform;