import React from 'react';
import styled, { keyframes } from 'styled-components';

const cloudMove = keyframes`
  0% { transform: translateX(100%); }
  100% { transform: translateX(-100%); }
`;

const leafFall = keyframes`
  0% {
    transform: translateY(-10vh) translateX(0) rotate(0deg);
    opacity: 1;
  }
  100% {
    transform: translateY(110vh) translateX(${() => Math.random() * 200 - 100}px) rotate(360deg);
    opacity: 0.6;
  }
`;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: -1;
`;

const Sky = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 100px;
  background: linear-gradient(180deg, #87CEEB 0%, #E0F6FF 100%);
`;

const CloudBase = styled.div`
  position: absolute;
  background: white;
  opacity: 0.8;
  animation: ${cloudMove} linear infinite;
  animation-duration: ${props => props.speed || '30s'};
  top: ${props => props.top || '20%'};
`;

const Cloud1 = styled(CloudBase)`
  width: 200px;
  height: 60px;
  border-radius: 200px;
  &:before, &:after {
    content: '';
    position: absolute;
    background: white;
    border-radius: 50%;
  }
  &:before {
    width: 80px;
    height: 80px;
    top: -40px;
    left: 10px;
  }
  &:after {
    width: 100px;
    height: 100px;
    top: -50px;
    right: 10px;
  }
`;

const Cloud2 = styled(CloudBase)`
  width: 150px;
  height: 50px;
  border-radius: 150px;
  &:before {
    content: '';
    position: absolute;
    background: white;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    top: -70px;
    right: 0;
  }
`;

const Cloud3 = styled(CloudBase)`
  width: 180px;
  height: 40px;
  border-radius: 180px;
  &:before, &:after {
    content: '';
    position: absolute;
    background: white;
    border-radius: 50%;
  }
  &:before {
    width: 90px;
    height: 90px;
    top: -45px;
    left: 5px;
  }
  &:after {
    width: 70px;
    height: 70px;
    top: -35px;
    right: 15px;
  }
`;

const Tree = styled.div`
  position: absolute;
  bottom: 0;
  width: ${props => props.width || '40px'};
  height: ${props => props.height || '200px'};
  background: #8B4513;
  &:before {
    content: '';
    position: absolute;
    top: ${props => `-${props.crownHeight || '180px'}`};
    left: ${props => `-${parseInt(props.width) * 3.25}px` || '-130px'};
    width: ${props => `${parseInt(props.width) * 7.5}px` || '300px'};
    height: ${props => props.crownHeight || '200px'};
    background: ${props => props.color || '#FFA500'};
    border-radius: 50% 50% 50% 50% / 60% 60% 40% 40%;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1) inset;
  }
  &:after {
    content: '';
    position: absolute;
    top: ${props => `-${parseInt(props.crownHeight) * 0.8}px` || '-150px'};
    left: ${props => `-${parseInt(props.width) * 2.5}px` || '-100px'};
    width: ${props => `${parseInt(props.width) * 6}px` || '240px'};
    height: ${props => `${parseInt(props.crownHeight) * 0.8}px` || '160px'};
    background: ${props => props.color || '#FFA500'};
    border-radius: 50% 50% 50% 50% / 60% 60% 40% 40%;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1) inset;
  }
`;

const Leaf = styled.div`
  position: absolute;
  width: 15px;
  height: 15px;
  background: ${props => props.color || '#FF6347'};
  opacity: 0.7;
  border-radius: 0 50% 50% 50%;
  transform: rotate(45deg);
  animation: ${leafFall} ${props => props.duration || '10s'} linear infinite;
  animation-delay: ${props => props.delay || '0s'};
  left: ${props => props.left || '0'};
  top: ${props => props.top || '0'};
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, transparent 45%, rgba(255,255,255,0.5) 50%, transparent 55%);
  }
`;

const Grass = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100px;
  background: linear-gradient(180deg, #4a8f29 0%, #45a049 100%);
`;

const AnimatedBackground = () => {
    const trees = [
      { left: '5%', color: '#FFA500', height: '220px', width: '44px', crownHeight: '200px' },
      { left: '15%', color: '#FF8C00', height: '180px', width: '36px', crownHeight: '160px' },
      { left: '35%', color: '#FFD700', height: '200px', width: '40px', crownHeight: '180px' },
      { right: '25%', color: '#FF7F50', height: '190px', width: '38px', crownHeight: '170px' },
      { right: '5%', color: '#FF4500', height: '230px', width: '46px', crownHeight: '210px' },
      { right: '15%', color: '#FF6347', height: '210px', width: '42px', crownHeight: '190px' },
    ];
  
    return (
      <Container>
        <Sky>
          <Cloud1 top="10%" speed="120s" />
          <Cloud2 top="30%" speed="90s" />
          <Cloud3 top="50%" speed="150s" />
        </Sky>
        {trees.map((tree, index) => (
          <Tree key={index} style={tree} color={tree.color} height={tree.height} width={tree.width} crownHeight={tree.crownHeight} />
        ))}
        {[...Array(30)].map((_, i) => (
          <Leaf
            key={i}
            color={['#FF6347', '#FF4500', '#FFD700', '#FFA500'][i % 4]}
            duration={`${15 + Math.random() * 10}s`}
            delay={`-${Math.random() * 15}s`}
            left={`${Math.random() * 100}%`}
            top={`${Math.random() * 110}vh`}
          />
        ))}
        <Grass />
      </Container>
    );
  };
  
  export default AnimatedBackground;