import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { updateUser } from './userSlice';

export const fetchFarm = createAsyncThunk('farm/fetchFarm', async (initData, { dispatch, rejectWithValue }) => {
  try {
    const response = await fetch('/api/farm', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ initData }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to fetch farm data');
    }

    const data = await response.json();
    console.log('Received farm data:', JSON.stringify(data, null, 2));
    
    // Обновляем данные пользователя
    dispatch(updateUser({
      level: data.level,
      experience: data.experience,
      username: data.username,
      photoUrl: data.photoUrl,
      telegramId: data.telegramId,
      title: data.title,
      referralCode: data.referralCode,
      referrals: data.referrals,
    }));
    
    return data;
  } catch (error) {
    console.error('Error in fetchFarm:', error);
    return rejectWithValue(error.message);
  }
});

export const plantCrop = createAsyncThunk('farm/plantCrop', async ({ index, cropType, telegramId }, { rejectWithValue }) => {
  try {
    const response = await fetch('/api/plant', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ telegramId, cropType, index }),
    });
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to plant crop');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const updateBalance = createAsyncThunk(
  'farm/updateBalance',
  async ({ telegramId, newBalance }, { rejectWithValue }) => {
    try {
      const response = await fetch('/api/update-balance', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ telegramId, newBalance }),
      });
      if (!response.ok) {
        throw new Error('Failed to update balance');
      }
      return await response.json();
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const harvestCrop = createAsyncThunk(
  'farm/harvestCrop',
  async ({ index, telegramId }, { dispatch, rejectWithValue }) => {
    try {
      const response = await fetch('/api/harvest', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ telegramId, cropIndex: index }),
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || 'Failed to harvest crop');
      }
      // Обновляем данные пользователя
      dispatch(updateUser({ level: data.level, experience: data.experience }));
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const buyPlot = createAsyncThunk('farm/buyPlot', async ({ telegramId }, { rejectWithValue }) => {
  try {
    const response = await fetch('/api/buy-plot', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ telegramId }),
    });
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to buy plot');
    }
    return response.json();
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const buySeeds = createAsyncThunk('farm/buySeeds', async ({ seedType, amount, telegramId }, { rejectWithValue }) => {
  try {
    const response = await fetch('/api/buy-seeds', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ telegramId, seedType, amount }),
    });
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to buy seeds');
    }
    const data = await response.json();
    console.log('Buy seeds response:', data);
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const buyItem = createAsyncThunk(
  'farm/buyItem',
  async ({ itemType, itemCategory, amount, telegramId }, { rejectWithValue }) => {
    try {
      const response = await fetch('/api/buy-item', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ telegramId, itemType, itemCategory, amount }),
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to buy item');
      }
      const data = await response.json();
      console.log('Buy item response:', data);
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const applyFertilizer = createAsyncThunk(
  'farm/applyFertilizer',
  async ({ plotIndex, fertilizerType, telegramId }, { rejectWithValue }) => {
    try {
      const response = await fetch('/api/apply-fertilizer', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ telegramId, plotIndex, fertilizerType }),
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to apply fertilizer');
      }
      return await response.json();
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const farmSlice = createSlice({
  name: 'farm',
  initialState: {
    crops: [],
    size: 5,
    maxSize: 10,
    balance: 0,
    lastBonusTime: null,
    status: 'idle',
    plotPrices: [500, 1000, 1500, 2000, 2500],
    inventory: {},
    selectedSeed: null,
    error: null
  },
  reducers: {
    updateFarm: (state, action) => {
      return { ...state, ...action.payload };
    },
    updateFarmState: (state, action) => {
      return { ...state, ...action.payload };
    },
    buySeeds: (state, action) => {
      const { seedType, amount } = action.payload;
      if (state.inventory[seedType]) {
        state.inventory[seedType] += amount;
      } else {
        state.inventory[seedType] = amount;
      }
    },
    selectSeed: (state, action) => {
      state.selectedSeed = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFarm.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchFarm.fulfilled, (state, action) => {
        if (action.payload.isBlocked !== undefined) {
          state.isBlocked = action.payload.isBlocked;
        }
        Object.assign(state, action.payload);  // Обновляем state, не возвращая новый объект
        state.status = 'succeeded';
      })
      .addCase(fetchFarm.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;  // Используем action.error.message вместо action.payload
      })
      .addCase(plantCrop.fulfilled, (state, action) => {
        if (action.payload) {
          state.crops = action.payload.crops;
          state.balance = action.payload.balance;
          state.inventory = action.payload.inventory;
          // Удаляем console.log, не рекомендуется в продакшене
        }
      })
      .addCase(updateBalance.fulfilled, (state, action) => {
        state.balance = action.payload.balance;
      })
      .addCase(plantCrop.rejected, (state, action) => {
        state.error = action.error.message;  // Используем action.error.message вместо action.payload
      })
      .addCase(harvestCrop.rejected, (state, action) => {
        state.error = action.error.message;  // Используем action.error.message вместо action.payload
      })
      .addCase(buyPlot.fulfilled, (state, action) => {
        state.size = action.payload.size;
        state.balance = action.payload.balance;
        state.plotPrices = action.payload.plotPrices;
      })
      .addCase(buyPlot.rejected, (state, action) => {
        state.error = action.error.message;  // Используем action.error.message вместо action.payload
      })
      .addCase(buySeeds.fulfilled, (state, action) => {
        state.balance = action.payload.balance;
        state.inventory = action.payload.inventory;
        // Удаляем console.log, не рекомендуется в продакшене
      })
      .addCase(buyItem.fulfilled, (state, action) => {
        state.balance = action.payload.balance;
        state.inventory = action.payload.inventory;
      })
      .addCase(applyFertilizer.fulfilled, (state, action) => {
        state.crops = action.payload.crops;
        state.inventory = action.payload.inventory;
      })
      .addCase(buySeeds.rejected, (state, action) => {
        state.error = action.error.message;  // Используем action.error.message вместо action.payload
      })
      .addCase(harvestCrop.fulfilled, (state, action) => {
        // Удаляем console.log, не рекомендуется в продакшене
        if (action.payload) {
          state.crops = action.payload.crops;
          state.balance = action.payload.balance;
          state.inventory = action.payload.inventory;
        }
      });
  },
});

export const { updateFarmState, updateFarm, selectSeed } = farmSlice.actions;
export default farmSlice.reducer;