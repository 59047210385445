import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
//import BackGroundLoading from '../assets/crypto-farm-background.png';
import BackGroundLoading from '../assets/loading2.png';


const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end; /* Опускаем весь контент вниз */
  align-items: center;
  height: 100vh;
  background: url(${BackGroundLoading}) no-repeat center center fixed;
  background-size: cover;
  position: relative;
`;

const ProgressBar = styled.div`
  width: 80%;
  height: 20px;
  background-color: #ddd;
  border-radius: 10px;
  margin-bottom: 20px; /* Отступ над VersionText */
  position: absolute;
  bottom: 40px; /* Отступ от низа экрана */
`;

const Progress = styled.div`
  width: ${props => props.width}%;
  height: 100%;
  background-color: #4CAF50;
  border-radius: 10px;
  transition: width 0.5s ease-in-out;
`;

const LoadingText = styled.h2`
  font-family: 'Rocher';
  text-align: center;
  base-palette: 6;
  color: white;
  position: absolute;
  bottom: 80px; /* Над ProgressBar */
`;

const VersionText = styled.h3`
  font-family: 'Rocher';
  text-align: center;
  font-size: 20px;
  base-palette: 7;
  position: absolute;
  color: red;
  bottom: 10px; /* Под ProgressBar */
`;

const LoadingScreen = () => {
  const [progress, setProgress] = useState(0);
  const tg = window.Telegram?.WebApp;

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress(oldProgress => {
        if (oldProgress === 100) {
          clearInterval(timer);
          return 100;
        }
        const newProgress = oldProgress + 10;
        
        if (tg && tg.HapticFeedback) {
          tg.HapticFeedback.impactOccurred('medium');
        }
        
        return Math.min(newProgress, 100);
      });
    }, 200);

    return () => {
      clearInterval(timer);
    };
  }, [tg]);

  return (
    <LoadingContainer>
      <LoadingText>Your farm is loading...</LoadingText>
      <ProgressBar>
        <Progress width={progress} />
      </ProgressBar>
      <VersionText>
        version: 0.9.44
      </VersionText>
    </LoadingContainer>
  );
};

export default LoadingScreen;