import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TonConnectButton, useTonAddress, useTonConnectUI } from '@tonconnect/ui-react';
import styled from 'styled-components';
import { updateFarmState, fetchFarm } from '../farmSlice';
import { updateUser } from '../userSlice';

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  min-height: 100vh;
  background-color: #f0f8ff;
`;

const Header = styled.h1`
  font-size: 2em;
  margin-bottom: 20px;
`;

const StatusBar = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
`;

const InfoBlocks = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 20px;
`;

const InfoBlock = styled.div`
  font-size: 13px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  border: 1px solid #8B4513;
  border-radius: 10px;
  background-color: #FFFAF0;
  width: 40%;
`;

const Button = styled.button`
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
  margin-top: 10px;
  &:hover {
    background-color: #45a049;
  }
`;

const Divider = styled.hr`
  width: 100%;
  margin: 20px 0;
`;

const DepositOptions = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  padding-bottom: 40px;
`;

const DepositOption = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  margin: 10px;
  border: 1px solid #8B4513;
  border-radius: 10px;
  background-color: #FFFAF0;
  width: 35%;
  cursor: pointer;
  &:hover {
    background-color: #FFE4B5;
  }
`;

const MessageOverlay = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 20px;
  border-radius: 10px;
  z-index: 1000;
`;

const CoFounderText = styled.p`
  font-size: 15px;
  letter-spacing: 2px;
  word-spacing: 2px;
  color: #878787;
  text-decoration: none;
  font-style: italic;
`;
const TgLink = styled.a`
  color: #4CAF50;
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    color: #45a049;
  }
`;
const ProfilePage = () => {
  const dispatch = useDispatch();
  const { balance } = useSelector(state => state.farm);
  const { level, telegramId, username, referralCode, referrals } = useSelector(state => state.user);
  const [message, setMessage] = useState('');
  const userFriendlyAddress = useTonAddress();
  const [tonConnectUI] = useTonConnectUI();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const savedInitData = localStorage.getItem('telegramInitData');
        if (savedInitData) {
          const result = await dispatch(fetchFarm(savedInitData)).unwrap();
          console.log('Fetched farm data:', result); // Добавьте этот лог
          
          // Обновляем данные пользователя
          dispatch(updateUser({
            level: result.level,
            experience: result.experience,
            username: result.username,
            telegramId: result.telegramId,
            referralCode: result.referralCode,
            referrals: result.referrals,
          }));
        }
      } catch (error) {
        console.error('Fetch farm error:', error);
      }
    };
    fetchData();
  }, [dispatch]);

  const packages = [
    { coins: 10000, usd: 1, rub: 100 },
    { coins: 20000, usd: 2, rub: 200 },
    { coins: 50000, usd: 5, rub: 500 },
    { coins: 100000, usd: 10, rub: 1000 },
    { coins: 500000, usd: 50, rub: 5000 },
    { coins: 1000000, usd: 100, rub: 10000 },
  ];

  useEffect(() => {
    console.log('User data in ProfilePage:', { level, telegramId, username, referralCode, referrals });
  }, [level, telegramId, username, referralCode, referrals]);
  
  const tonRate = 462.15 / 100; // TON to USD rate

  const handleDeposit = async (packageItem) => {
    if (!userFriendlyAddress) {
      setMessage("Please connect your wallet first");
      return;
    }
  
    const tonAmount = (packageItem.usd / tonRate).toFixed(9);
    const nanotons = Math.floor(parseFloat(tonAmount) * 1e9).toString();
  
    const transaction = {
      validUntil: Math.floor(Date.now() / 1000) + 60 * 20,
      messages: [
        {
          address: "UQCLsrPlcX_eoe-hT9QMtpik3D54bVKD0DbmaiGXf7cYKDfv",
          amount: nanotons
        }
      ]
    };
  
    try {
      setMessage("Sending transaction...");
      const result = await tonConnectUI.sendTransaction(transaction);
      console.log("Transaction result:", result);
      
      setMessage(`Deposit of ${tonAmount} TON sent. Updating balance...`);
      
      // Обновление баланса пользователя локально через Redux
      const newBalance = balance + packageItem.coins;
      dispatch(updateFarmState({
        balance: newBalance,
      }));

      // Отправляем запрос на сервер для обновления баланса
      const response = await fetch('/api/update-balance', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          telegramId,
          newBalance,
          transactionId: result.boc
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to update balance on server');
      }

      setMessage(`Balance updated successfully! Added ${packageItem.coins} coins.`);
      setTimeout(() => setMessage(null), 3000);

    } catch (error) {
      console.error("Error in deposit process:", error);
      setMessage(`Error: ${error.message}. Please try again later.`);
      setTimeout(() => setMessage(null), 5000);
    }
  };

  const handleWithdraw = () => {
    const balanceInUSD = balance / 10000;
    if (balanceInUSD < 10) {
      setMessage('Минимальная сумма вывода - 10$.');
    } else {
      setMessage('Возможность вывести средства откроется 31 октября 2024.');
    }
    setTimeout(() => setMessage(''), 5000);
  };

  const handleCopyReferral = () => {
    console.log('Current referral code:', referralCode); // Добавьте этот лог
    if (referralCode) {
      navigator.clipboard.writeText(referralCode).then(() => {
        setMessage('Ваш реферальный код скопирован, отправьте его другу');
        
        // Открываем окно "Поделиться" в Telegram
        const shareUrl = `https://t.me/share/url?url=${encodeURIComponent(`https://t.me/crptfarmbot?start=ref_${referralCode}`)}&text=${encodeURIComponent('Присоединяйся к CryptoFarm! Используй мой реферальный код:')}`;
        window.open(shareUrl, '_blank');
      }).catch(err => {
        console.error('Failed to copy referral code:', err);
        setMessage('Не удалось скопировать реферальный код');
      });
    } else {
      console.log('Referral code is not available'); // Добавьте этот лог
      setMessage('Реферальный код недоступен');
    }
    setTimeout(() => setMessage(''), 3000);
  };

  return (
    <ProfileContainer>
      <Header>Профиль</Header>
      <StatusBar>
        <TonConnectButton />
      </StatusBar>
      <InfoBlocks>
        <InfoBlock>
          <h3>Баланс фермы</h3>
          <p>🪙 {balance} = ${(balance / 10000).toFixed(2)}</p>
          <Button onClick={handleWithdraw}>Вывод</Button>
        </InfoBlock>
        <InfoBlock>
          <h3>Рефералы</h3>
          <p>👥 {Array.isArray(referrals) ? referrals.length : 0}</p>
          {/* <p>Ваш код: {referralCode}</p> */}
          <Button onClick={handleCopyReferral}>Пригласить</Button>
        </InfoBlock>
      </InfoBlocks>
      <Divider />
      <h2>Пополнить счёт</h2>
      <center><CoFounderText>Пополняя счёт, ты помогаешь проекту в развитии! Стань <b>Co-Founder'ом сегодня</b> и начни зарабатывать с CryptoFarm.
      <br/>
      <TgLink href="https://t.me/cfthegame/5" target="_blank" rel="noopener noreferrer">Подробнее про Co-Founder</TgLink></CoFounderText></center>
      <DepositOptions>
        {packages.map((pkg, index) => (
          <DepositOption key={index} onClick={() => handleDeposit(pkg)}>
            <span>🪙 {pkg.coins.toLocaleString()}</span>
            <p>${pkg.usd.toFixed(2)} / {pkg.rub} ₽</p>
            <p>≈ {(pkg.usd / tonRate).toFixed(2)} TON</p>
          </DepositOption>
        ))}
      </DepositOptions>
      {message && <MessageOverlay>{message}</MessageOverlay>}
    </ProfileContainer>
  );
};

export default ProfilePage;