import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { buySeeds, fetchFarm, updateFarmState } from '../farmSlice';
import io from 'socket.io-client';
import { Link } from 'react-router-dom';
import Toast from './Toast';

// Импортируйте изображения для фонов
import wheatBg from '../assets/wheat-bg.png';
import cornBg from '../assets/corn-bg.png';
import potatoBg from '../assets/potato-bg.jpeg';
import carrotBg from '../assets/carrot-bg.png';
import tomatoBg from '../assets/tomato-bg.png';
import pumpkinBg from '../assets/pumpkin-bg.jpg';

const ShopContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  min-height: 100vh;
  background-color: #f0f8ff;
`;

const SeedItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  margin: 10px;
  border-radius: 10px;
  width: 180px;
  height: 250px;
  position: relative;
  overflow: hidden;
  cursor: ${props => props.isAvailable ? 'pointer' : 'not-allowed'};
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(${props => props.backgroundImage});
    background-size: cover;
    background-position: center;
    filter: blur(3px) brightness(0.7);
  }
`;

const OverlayMessage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 18px;
  text-align: center;
  z-index: 2;
`;

const SeedContent = styled.div`
  color: white;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.8);
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const SeedName = styled.h3`
  font-size: 1.2em;
  margin-bottom: 10px;
  text-align: center;
`;

const SeedInfo = styled.div`
  font-size: 1.5em;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top:
  margin-bottom: 10px;
`;

const InventoryBadge = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: rgba(255, 255, 255, 0.8);
  color: #4CAF50;
  border-radius: 0 0 0 10px;
  padding: 5px 10px;
  font-weight: bold;
`;

const TagsContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  padding: 5px;
  background-color: rgba(255, 255, 255, 0.8);
`;

const Tag = styled.div`
  flex: 1;
  padding: 5px;
  font-weight: bold;
  color: #4CAF50;
  text-align: center;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 35px;
  overflow: hidden;
  
  &:not(:last-child) {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }
`;

const StatusBar = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  flex-direction: column;
  align-content: center;
  flex-wrap: wrap;
`;

const BackToFarmButton = styled(Link)`
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  margin-top: 20px;
`;

const MessageOverlay = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 20px;
  border-radius: 10px;
  z-index: 1000;
`;

const Shop = () => {
  const dispatch = useDispatch();
  const { balance, inventory } = useSelector(state => state.farm);
  const { level, telegramId } = useSelector(state => state.user); 
  const [message] = useState('');
  const [seeds, setSeeds] = useState([]);
  const [toasts, setToasts] = useState([]);

  useEffect(() => {
    dispatch(fetchFarm());
    fetchSeeds();

    const socket = io('https://crptchrd.online');
    socket.on('connect', () => {
      console.log('Connected to WebSocket');
      socket.emit('join', '12345'); // Используйте реальный telegramId
    });

    socket.on('farmUpdate', (updatedFarm) => {
      console.log('Received farm update:', updatedFarm);
      dispatch(updateFarmState(updatedFarm));
    });

    return () => {
      socket.disconnect();
    };
  }, [dispatch]);

  const showToast = (message, type = 'info', duration = 3000) => {
    const id = Date.now();
    setToasts(prevToasts => [...prevToasts, { id, message, type }]);
    setTimeout(() => removeToast(id), duration);
  };

  const removeToast = (id) => {
    setToasts(prevToasts => prevToasts.filter(toast => toast.id !== id));
  };

  const fetchSeeds = async () => {
    try {
      const response = await fetch('/api/crops');
      if (response.ok) {
        const data = await response.json();
        setSeeds(data);
      } else {
        throw new Error('Failed to fetch crops data');
      }
    } catch (error) {
      console.error('Error fetching seeds:', error);
      showToast('Error loading shop data. Please try again later.', "error", 3000);
    }
  };

  const handleBuySeeds = async (seedType, cost, minLevel) => {
    if (level < minLevel) {
      showToast(`Требуется уровень ${minLevel} для покупки этих семян!`, "error", 3000);
      return;
    }
    if (!telegramId) {
      console.error('TelegramId is not available');
      showToast('Error: User ID not available', "error", 3000);
      return;
    }
    if (balance < cost) {
      showToast('Недостаточно денег для покупки семян!', "error", 3000);
      return;
    }
    try {
      console.log('Buying seeds:', seedType, cost, 'for user:', telegramId);
      const result = await dispatch(buySeeds({ seedType, amount: 1, telegramId })).unwrap();
      console.log('Buy seeds result:', result);
      showToast(`Успешно куплены семена ${seedType}!`, "success", 3000);
    } catch (error) {
      console.error('Error buying seeds:', error);
      showToast(`Ошибка при покупке семян: ${error.message}`, "error", 3000);
    }
  };

  const getBackgroundImage = (cropType) => {
    const bgMap = {
      wheat: wheatBg,
      corn: cornBg,
      potato: potatoBg,
      carrot: carrotBg,
      tomato: tomatoBg,
      pumpkin: pumpkinBg
    };
    return bgMap[cropType] || '';
  };

  return (
    <ShopContainer>
      <h2>Магазин "Всё для Фермера"</h2>
      <StatusBar>
        <span>🪙 {balance}</span>
        <span>Уровень: {level}</span>
      </StatusBar>
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
        {seeds.map(seed => (
          <SeedItem 
            key={seed.type} 
            backgroundImage={getBackgroundImage(seed.type)}
            onClick={() => handleBuySeeds(seed.type, seed.cost, seed.minLevel)}
            isAvailable={level >= seed.minLevel}
          >
            <SeedContent>
              <SeedName>{getEmojiForCrop(seed.type)} {seed.type}</SeedName>
              <SeedInfo>
                <span>⭐ {seed.xp}</span>
                <span>🍀 {(seed.seedDropChance * 100).toFixed(1)}%</span>
              </SeedInfo>
            </SeedContent>
            <InventoryBadge>📦 {inventory[seed.type] || 0}</InventoryBadge>
            <TagsContainer>
              <Tag>
                🪙{seed.cost}
              </Tag>
              <Tag>
                ⌚{formatGrowthTime(seed.growthTime)}
              </Tag>
              <Tag>
                💰{seed.value}
              </Tag>
            </TagsContainer>
            {level < seed.minLevel && (
              <OverlayMessage>
                <span>⛔</span>
                <p>Доступно с уровня {seed.minLevel}</p>
              </OverlayMessage>
            )}
          </SeedItem>
        ))}
      </div>
      <BackToFarmButton to="/">Вернуться на ферму</BackToFarmButton>
      {message && <MessageOverlay>{message}</MessageOverlay>}
      {toasts.map(toast => (
        <Toast
          key={toast.id}
          message={toast.message}
          onClose={() => removeToast(toast.id)}
        />
      ))}
    </ShopContainer>
  );
};

const getEmojiForCrop = (cropType) => {
  const emojiMap = {
    wheat: '🌾',
    corn: '🌽',
    potato: '🥔',
    carrot: '🥕',
    tomato: '🍅',
    pumpkin: '🎃'
  };
  return emojiMap[cropType] || '🌱';
};

const formatGrowthTime = (milliseconds) => {
  const minutes = Math.floor(milliseconds / 60000);
  return `${minutes}м.`;
};

export default Shop;