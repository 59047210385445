import { createSlice } from '@reduxjs/toolkit';
import { harvestCrop } from './farmSlice';

const initialState = {
  level: 1,
  experience: 0,
  completedQuests: [],
  isBlocked: false,
  telegramId: '',
  username: '',
  title: '',
  customTitle: null,
  photoUrl: '',
  referralCode: '',
  referrals: [],
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUser: (state, action) => {
      return { ...state, ...action.payload };
    },
    setCustomTitle: (state, action) => {
      state.customTitle = action.payload;
      state.title = action.payload;
    },
    setBlockedStatus: (state, action) => {
      state.isBlocked = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(harvestCrop.fulfilled, (state, action) => {
      if (action.payload.experience !== undefined) {
        state.experience = action.payload.experience;
      }
      if (action.payload.level !== undefined) {
        state.level = action.payload.level;
      }
      if (!state.customTitle) {
        state.title = getPlayerTitle(state.level);
      }
    });
    builder.addCase('quests/completeQuest/fulfilled', (state, action) => {
      if (action.payload.user && action.payload.user.completedQuests) {
        state.completedQuests = action.payload.user.completedQuests;
      }
    });
    builder.addCase('farm/fetchFarm/fulfilled', (state, action) => {
      if (action.payload.isBlocked !== undefined) {
        state.isBlocked = action.payload.isBlocked;
      }
    });
    builder.addCase('farm/fetchFarm/rejected', (state, action) => {
      if (action.error.message === 'User is blocked') {
        state.isBlocked = true;
      }
    });
  },
});

const getPlayerTitle = (level) => {
  const titles = {
    1: 'Фермер',
    2: 'Садовод',
    3: 'Землевладелец',
    4: 'Агроном',
    5: 'Лорд Полей'
  };
  return titles[level] || titles[1];
};

export const { updateUser, setCustomTitle, setBlockedStatus } = userSlice.actions;
export default userSlice.reducer;
