import React, { useState, useEffect } from 'react';
import { UserMinusIcon, NoSymbolIcon } from '@heroicons/react/20/solid';
import styled from 'styled-components';
import AdminLogin from './AdminLogin';

const AdminContainer = styled.div`
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #f3f4f6;
`;

const Section = styled.div`
  margin-bottom: 30px;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 5px;
`;

const Title = styled.h2`
  margin-bottom: 15px;
`;

const Input = styled.input`
  margin-right: 10px;
  padding: 5px;
`;

const Button = styled.button`
  padding: 5px 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
  &:hover {
    background-color: #45a049;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
`;

const Th = styled.th`
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
`;

const Td = styled.td`
  border: 1px solid #ddd;
  padding: 8px;
`;

const AdminPanel = () => {
    const [crops, setCrops] = useState([]);
    const [newCrop, setNewCrop] = useState({ type: '', growthTime: '', value: '', cost: '', xp: '', minLevel: '', seedDropChance: '' });
    const [experienceTable, setExperienceTable] = useState([]);
    const [users, setUsers] = useState([]);
    const [sortedUsers, setSortedUsers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [usersPerPage] = useState(12);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [quests, setQuests] = useState([]);
    const [newQuest, setNewQuest] = useState({
        title: '',
        description: '',
        type: 'SUBSCRIBE',
        requirement: 1,
        reward: {
            coins: 0,
            experience: 0,
            seeds: {}
        },
        isRepeatable: false,
        cooldown: 0,
        channelLink: ''
    });
  
    useEffect(() => {
      const token = localStorage.getItem('adminToken');
      if (token) {
        setIsLoggedIn(true);
        fetchCrops();
        fetchExperienceTable();
        fetchUsers();
        fetchQuests();
      }
    }, []);
    const [editingQuest, setEditingQuest] = useState(null);

    useEffect(() => {
      // Sort users by lastLogin in descending order
      const sorted = [...users].sort((a, b) => new Date(b.lastLogin) - new Date(a.lastLogin));
      setSortedUsers(sorted);
  }, [users]);

    const handleEditQuest = (quest) => {
      setEditingQuest(quest);
      setNewQuest(quest);
  };
  
    const fetchCrops = async () => {
    try {
      const response = await fetch('/api/admin/crops', {
        headers: {
          'x-auth-token': localStorage.getItem('adminToken'),
        },
      });
      if (response.ok) {
        const data = await response.json();
        setCrops(data);
      } else {
        throw new Error('Failed to fetch crops');
      }
    } catch (error) {
      console.error('Error fetching crops:', error);
      alert('Failed to fetch crops. Please try logging in again.');
      setIsLoggedIn(false);
      localStorage.removeItem('adminToken');
    }
  };

  const fetchExperienceTable = async () => {
    try {
      const response = await fetch('/api/admin/experience-table', {
        headers: {
          'x-auth-token': localStorage.getItem('adminToken'),
        },
      });
      if (response.ok) {
        const data = await response.json();
        setExperienceTable(data);
      } else {
        throw new Error('Failed to fetch experience table');
      }
    } catch (error) {
      console.error('Error fetching experience table:', error);
    }
  };

  const handleNewCropChange = (e) => {
    setNewCrop({ ...newCrop, [e.target.name]: e.target.value });
  };

  const fetchUsers = async () => {
    try {
      const response = await fetch('/api/admin/users', {
        headers: {
          'x-auth-token': localStorage.getItem('adminToken'),
        },
      });
      if (response.ok) {
        const data = await response.json();
        setUsers(data);
      } else {
        throw new Error('Failed to fetch users');
      }
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const fetchQuests = async () => {
    try {
        const response = await fetch('/api/admin/quests', {
            headers: {
                'x-auth-token': localStorage.getItem('adminToken'),
            },
        });
        if (response.ok) {
            const data = await response.json();
            setQuests(data);
        } else {
            throw new Error('Failed to fetch quests');
        }
    } catch (error) {
        console.error('Error fetching quests:', error);
        alert('Failed to fetch quests. Please try again.');
    }
};

const handleNewQuestChange = (e) => {
  const { name, value } = e.target;
  if (name.startsWith('reward.')) {
      const rewardField = name.split('.')[1];
      setNewQuest(prev => ({
          ...prev,
          reward: {
              ...prev.reward,
              [rewardField]: value
          }
      }));
  } else {
      setNewQuest(prev => ({ ...prev, [name]: value }));
  }
};

const addNewQuest = async () => {
  try {
      const response = await fetch('/api/admin/quests', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'x-auth-token': localStorage.getItem('adminToken'),
          },
          body: JSON.stringify(newQuest),
      });
      if (response.ok) {
          fetchQuests();
          setNewQuest({
              title: '',
              description: '',
              type: 'SUBSCRIBE',
              requirement: 1,
              reward: {
                  coins: 0,
                  experience: 0,
                  seeds: {}
              },
              isRepeatable: false,
              cooldown: 0,
              channelLink: ''
          });
      } else {
          throw new Error('Failed to add new quest');
      }
  } catch (error) {
      console.error('Error adding new quest:', error);
      alert('Failed to add new quest. Please try again.');
  }
};

const updateQuest = async () => {
  try {
      const response = await fetch(`/api/admin/quests/${editingQuest._id}`, {
          method: 'PUT',
          headers: {
              'Content-Type': 'application/json',
              'x-auth-token': localStorage.getItem('adminToken'),
          },
          body: JSON.stringify(newQuest),
      });
      if (response.ok) {
          fetchQuests();
          setEditingQuest(null);
          setNewQuest({
              title: '',
              description: '',
              type: 'SUBSCRIBE',
              requirement: 1,
              reward: {
                  coins: 0,
                  experience: 0,
                  seeds: {}
              },
              isRepeatable: false,
              cooldown: 0,
              channelLink: ''
          });
      } else {
          throw new Error('Failed to update quest');
      }
  } catch (error) {
      console.error('Error updating quest:', error);
      alert('Failed to update quest. Please try again.');
  }
};

const deleteQuest = async (id) => {
  if (window.confirm('Are you sure you want to delete this quest?')) {
      try {
          const response = await fetch(`/api/admin/quests/${id}`, {
              method: 'DELETE',
              headers: {
                  'x-auth-token': localStorage.getItem('adminToken'),
              },
          });
          if (response.ok) {
              fetchQuests();
          } else {
              throw new Error('Failed to delete quest');
          }
      } catch (error) {
          console.error('Error deleting quest:', error);
          alert('Failed to delete quest. Please try again.');
      }
  }
};

  const addNewCrop = async () => {
    try {
      const response = await fetch('/api/admin/crops', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': localStorage.getItem('adminToken'),
        },
        body: JSON.stringify(newCrop),
      });
      if (response.ok) {
        fetchCrops();
        setNewCrop({ type: '', growthTime: '', value: '', cost: '', xp: '', minLevel: '', seedDropChance: '' });
      } else {
        throw new Error('Failed to add new crop');
      }
    } catch (error) {
      console.error('Error adding new crop:', error);
      alert('Failed to add new crop. Please try again.');
    }
  };

  const updateCrop = async (type, updatedCrop) => {
    try {
      console.log('Updating crop:', type, updatedCrop); // Добавьте это логирование
      const response = await fetch(`/api/admin/crops/${type}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': localStorage.getItem('adminToken'),
        },
        body: JSON.stringify(updatedCrop),
      });
      if (response.ok) {
        fetchCrops();
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to update crop');
      }
    } catch (error) {
      console.error('Error updating crop:', error);
      alert('Failed to update crop. Please try again.');
    }
  };

  const updateExperienceTable = async () => {
    try {
      const response = await fetch('/api/admin/experience-table', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': localStorage.getItem('adminToken'),
        },
        body: JSON.stringify(experienceTable),
      });
      if (response.ok) {
        alert('Experience table updated successfully');
      } else {
        throw new Error('Failed to update experience table');
      }
    } catch (error) {
      console.error('Error updating experience table:', error);
      alert('Failed to update experience table. Please try again.');
    }
  };

  const updateUser = async (id, updatedUser) => {
    try {
      const response = await fetch(`api/admin/users/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': localStorage.getItem('adminToken'),
        },
        body: JSON.stringify(updatedUser),
      });
      if (response.ok) {
        fetchUsers();
      } else {
        throw new Error('Failed to update user');
      }
    } catch (error) {
      console.error('Error updating user:', error);
      alert('Failed to update user. Please try again.');
    }
  };

  const updateUserTitle = async (id, newTitle) => {
    try {
      const response = await fetch(`/api/admin/users/${id}/title`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': localStorage.getItem('adminToken'),
        },
        body: JSON.stringify({ title: newTitle }),
      });
      if (response.ok) {
        fetchUsers();
      } else {
        throw new Error('Failed to update user title');
      }
    } catch (error) {
      console.error('Error updating user title:', error);
      alert('Failed to update user title. Please try again.');
    }
  };

  const deleteUser = async (id) => {
    if (window.confirm('Are you sure you want to delete this user? This action cannot be undone.')) {
      try {
        const response = await fetch(`/api/admin/users/${id}`, {
          method: 'DELETE',
          headers: {
            'x-auth-token': localStorage.getItem('adminToken'),
          },
        });
        if (response.ok) {
          fetchUsers();
          alert('User deleted successfully');
        } else {
          throw new Error('Failed to delete user');
        }
      } catch (error) {
        console.error('Error deleting user:', error);
        alert('Failed to delete user. Please try again.');
      }
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('adminToken');
    setIsLoggedIn(false);
  };

  // Get current users
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = sortedUsers.slice(indexOfFirstUser, indexOfLastUser);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  if (!isLoggedIn) {
    return <AdminLogin onLogin={() => setIsLoggedIn(true)} />;
  }

  return (
    <AdminContainer>
        <h2 className="text-2xl font-bold mb-6">Admin Panel</h2>
        <button
            onClick={handleLogout}
            className="absolute top-4 right-4 px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700"
        >
            Logout
        </button>
      
      <Section>
        <h3>Add New Crop</h3>
        <Input name="type" value={newCrop.type} onChange={handleNewCropChange} placeholder="Crop Type" />
        <Input name="growthTime" value={newCrop.growthTime} onChange={handleNewCropChange} placeholder="Growth Time (ms)" type="number" />
        <Input name="value" value={newCrop.value} onChange={handleNewCropChange} placeholder="Value" type="number" />
        <Input name="cost" value={newCrop.cost} onChange={handleNewCropChange} placeholder="Cost" type="number" />
        <Input name="xp" value={newCrop.xp} onChange={handleNewCropChange} placeholder="XP" type="number" />
        <Input name="minLevel" value={newCrop.minLevel} onChange={handleNewCropChange} placeholder="Min Level" type="number" />
        <Input name="seedDropChance" value={newCrop.seedDropChance} onChange={handleNewCropChange} placeholder="Seed Drop Chance" type="number" step="0.01" />
        <Button onClick={addNewCrop}>Add Crop</Button>
      </Section>

      <Section>
        <h3>Existing Crops</h3>
        <Table>
          <thead>
            <tr>
              <Th>Type</Th>
              <Th>Growth Time</Th>
              <Th>Value</Th>
              <Th>Cost</Th>
              <Th>XP</Th>
              <Th>Min Level</Th>
              <Th>Seed Drop Chance</Th>
              <Th>Actions</Th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(crops).map(([type, crop]) => (
              <tr key={type}>
                <Td>{type}</Td>
                <Td>{crop.growthTime}</Td>
                <Td>{crop.value}</Td>
                <Td>{crop.cost}</Td>
                <Td>{crop.xp}</Td>
                <Td>{crop.minLevel}</Td>
                <Td>{crop.seedDropChance}</Td>
                <Td>
                <Button onClick={() => {
                    const updatedCrop = prompt('Enter updated crop data (JSON format):', JSON.stringify(crop));
                    if (updatedCrop) {
                        const parsedCrop = JSON.parse(updatedCrop);
                        updateCrop(parsedCrop.type, parsedCrop);
                    }
                    }}>Edit</Button>
                </Td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Section>

      <Section>
        <h3>Experience Table</h3>
        <Table>
          <thead>
            <tr>
              <Th>Level</Th>
              <Th>XP Needed</Th>
            </tr>
          </thead>
          <tbody>
            {experienceTable.map((entry, index) => (
              <tr key={index}>
                <Td>
                  <Input 
                    value={entry.level} 
                    onChange={(e) => {
                      const newTable = [...experienceTable];
                      newTable[index].level = parseInt(e.target.value);
                      setExperienceTable(newTable);
                    }} 
                    type="number"
                  />
                </Td>
                <Td>
                  <Input 
                    value={entry.xpNeeded} 
                    onChange={(e) => {
                      const newTable = [...experienceTable];
                      newTable[index].xpNeeded = parseInt(e.target.value);
                      setExperienceTable(newTable);
                    }} 
                    type="number"
                  />
                </Td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Button onClick={updateExperienceTable}>Update Experience Table</Button>
      </Section>
      <div className="mt-8">
                <h3 className="text-xl font-semibold mb-4">Users</h3>
                <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                    {currentUsers.map((user) => (
                        <li key={user._id} className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
                            <div className="flex w-full items-center justify-between space-x-6 p-6">
                                <div className="flex-1 truncate">
                                <div className="rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
                                  <label htmlFor="name" className="block text-xs font-medium text-gray-900">
                                    Title
                                  </label>
                                  <input
                                    type="text"
                                    name="name"
                                    id="name"
                                    className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                    placeholder={user.title || ''}
                                    onChange={(e) => {
                                      const newUsers = users.map(u =>
                                        u._id === user._id ? { ...u, title: e.target.value } : u
                                      );
                                      setUsers(newUsers);
                                    }}
                                    onBlur={() => updateUserTitle(user._id, user.title)}
                                  />
                                </div>
                                    <div className="flex items-center space-x-3">
                                      
                                        <h3 className="truncate text-sm font-medium text-gray-900">{user.username}</h3>
                                        <span className={`inline-flex flex-shrink-0 items-center rounded-full px-1.5 py-0.5 text-xs font-medium ${user.isBlocked ? 'bg-red-50 text-red-700 ring-red-600/20' : 'bg-green-50 text-green-700 ring-green-600/20'} ring-1 ring-inset`}>
                                            {user.isBlocked ? 'Blocked' : 'Active'}
                                        </span>
                                        <span class="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">ID: {user.telegramId}</span>
                                    </div>
                                    <p className="mt-1 truncate text-sm text-gray-500">Level: {user.level}</p>
                                    <p className="mt-1 truncate text-sm text-gray-500">Experience: {user.experience}</p>
                                    <p className="mt-1 truncate text-sm text-gray-500">Balance: 🪙{user.balance}</p>
                                    <p className="mt-1 truncate text-sm text-gray-500">Last login: {new Date(user.lastLogin).toLocaleString()}</p>
                                </div>
                                <img className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-300" src={`https://api.dicebear.com/6.x/initials/svg?seed=${user.username}`} alt="" />
                            </div>
                            <div>
                                <div className="-mt-px flex divide-x divide-gray-200">
                                    <div className="flex w-0 flex-1">
                                        <button
                                            onClick={() => {
                                                const updatedUser = { ...user, isBlocked: !user.isBlocked };
                                                updateUser(user._id, updatedUser);
                                            }}
                                            className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                                        >
                                            <NoSymbolIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                            {user.isBlocked ? 'Unblock' : 'Block'}
                                        </button>
                                    </div>
                                    <div className="-ml-px flex w-0 flex-1">
                                        <button
                                            onClick={() => deleteUser(user._id)}
                                            className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                                        >
                                            <UserMinusIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                            Delete
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
                <div className="mt-4">
                    <nav className="flex items-center justify-between">
                        <div>
                            <p className="text-sm text-gray-700">
                                Showing <span className="font-medium">{indexOfFirstUser + 1}</span> to <span className="font-medium">{Math.min(indexOfLastUser, sortedUsers.length)}</span> of{' '}
                                <span className="font-medium">{sortedUsers.length}</span> results
                            </p>
                        </div>
                        <div>
                            <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                                <button
                                    onClick={() => paginate(currentPage - 1)}
                                    disabled={currentPage === 1}
                                    className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                                >
                                    Previous
                                </button>
                                {[...Array(Math.ceil(sortedUsers.length / usersPerPage)).keys()].map((number) => (
                                    <button
                                        key={number + 1}
                                        onClick={() => paginate(number + 1)}
                                        className={`relative inline-flex items-center px-4 py-2 border text-sm font-medium ${
                                            currentPage === number + 1
                                                ? 'z-10 bg-indigo-50 border-indigo-500 text-indigo-600'
                                                : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'
                                        }`}
                                    >
                                        {number + 1}
                                    </button>
                                ))}
                                <button
                                    onClick={() => paginate(currentPage + 1)}
                                    disabled={currentPage === Math.ceil(sortedUsers.length / usersPerPage)}
                                    className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                                >
                                    Next
                                </button>
                            </nav>
                        </div>
                    </nav>
                </div>
            </div>
            <br/>
            <br/>
      <Section>
                <h3>Quests</h3>
                <Table>
                    <thead>
                        <tr>
                            <Th>Title</Th>
                            <Th>Description</Th>
                            <Th>Type</Th>
                            <Th>Requirement</Th>
                            <Th>Reward</Th>
                            <Th>Repeatable</Th>
                            <Th>Cooldown</Th>
                            <Th>Channel Link</Th>
                            <Th>Actions</Th>
                        </tr>
                    </thead>
                    <tbody>
                        {quests.map((quest) => (
                            <tr key={quest._id}>
                                <Td>{quest.title}</Td>
                                <Td>{quest.description}</Td>
                                <Td>{quest.type}</Td>
                                <Td>{quest.requirement}</Td>
                                <Td>
                                    Coins: {quest.reward.coins}, 
                                    XP: {quest.reward.experience}, 
                                    Seeds: {JSON.stringify(quest.reward.seeds)}
                                </Td>
                                <Td>{quest.isRepeatable ? 'Yes' : 'No'}</Td>
                                <Td>{quest.cooldown}</Td>
                                <Td>{quest.channelLink}</Td>
                                <Td>
                                    <Button onClick={() => handleEditQuest(quest)}>Edit</Button>
                                    <Button onClick={() => deleteQuest(quest._id)} style={{ backgroundColor: '#ff4d4d' }}>Delete</Button>
                                </Td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Section>

            <Section>
                <h3>{editingQuest ? 'Edit Quest' : 'Add New Quest'}</h3>
                <Input name="title" value={newQuest.title} onChange={handleNewQuestChange} placeholder="Title" />
                <Input name="description" value={newQuest.description} onChange={handleNewQuestChange} placeholder="Description" />
                <select name="type" value={newQuest.type} onChange={handleNewQuestChange}>
                    <option value="SUBSCRIBE">Subscribe</option>
                    <option value="HARVEST">Harvest</option>
                    <option value="PLANT">Plant</option>
                </select>
                <Input name="requirement" type="number" value={newQuest.requirement} onChange={handleNewQuestChange} placeholder="Requirement" />
                <Input name="reward.coins" type="number" value={newQuest.reward.coins} onChange={handleNewQuestChange} placeholder="Reward Coins" />
                <Input name="reward.experience" type="number" value={newQuest.reward.experience} onChange={handleNewQuestChange} placeholder="Reward Experience" />
                <Input name="isRepeatable" type="checkbox" checked={newQuest.isRepeatable} onChange={(e) => setNewQuest(prev => ({ ...prev, isRepeatable: e.target.checked }))} />
                <label>Is Repeatable</label>
                <Input name="cooldown" type="number" value={newQuest.cooldown} onChange={handleNewQuestChange} placeholder="Cooldown (ms)" />
                <Input name="channelLink" value={newQuest.channelLink} onChange={handleNewQuestChange} placeholder="Channel Link" />
                {editingQuest ? (
                    <>
                        <Button onClick={updateQuest}>Update Quest</Button>
                        <Button onClick={() => {
                            setEditingQuest(null);
                            setNewQuest({
                                title: '',
                                description: '',
                                type: 'SUBSCRIBE',
                                requirement: 1,
                                reward: {
                                    coins: 0,
                                    experience: 0,
                                    seeds: {}
                                },
                                isRepeatable: false,
                                cooldown: 0,
                                channelLink: ''
                            });
                        }}>Cancel</Button>
                    </>
                ) : (
                    <Button onClick={addNewQuest}>Add Quest</Button>
                )}
            </Section>
        </AdminContainer>
  );
};

export default AdminPanel;