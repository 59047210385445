import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import NonAvatar from '../assets/nonavatarfarmer.jpeg';
import lvlStar from '../assets/lvl_star.png'

const LeaderboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  min-height: 100vh;
  background-color: #f0f8ff;
`;

const LeaderboardTitle = styled.h2`
  font-size: 24px;
  margin-bottom: 20px;
`;

const LeaderboardList = styled.ul`
  list-style-type: none;
  padding: 0;
  width: 100%;
  max-width: 600px;
`;

const LeaderboardItem = styled.li`
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const PlayerAvatar = styled.img`
 position: relative;
 width: 40px;
 height: 40px;
 border-radius: 50%;
 margin-right: 10px;
`;

const LvlStarImg = styled.div`
 position: absolute;
 width: 35px;
 height: 35px;
 right: -3px;
 bottom: -5px;
 background-image: url(${props => props.src});
 background-size: cover;
 display: flex;
 align-items: center;
 justify-content: center;
 font-size: 10px;
 color: white;
 font-weight: bold;
`;

const PlayerInfo = styled.div`
  flex-grow: 1;
`;

const PlayerName = styled.span`
  font-weight: bold;
`;

const PlayerLevel = styled.span`
  color: #666;
  font-weight: bold;
`;

const PlayerBalance = styled.span`
  font-weight: bold;
  color: #4caf50;
`;

const BackToFarmButton = styled(Link)`
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  text-decoration: none;
  border-radius: 5px;
`;

const Leaderboard = () => {
  const [leaderboardData, setLeaderboardData] = useState([]);

  const fetchLeaderboard = async () => {
    try {
      const response = await fetch('/api/leaderboard');
      if (response.ok) {
        const data = await response.json();
        setLeaderboardData(data);
        console.log('Fetched leaderboard data:', data);
      } else {
        console.error('Failed to fetch leaderboard data');
      }
    } catch (error) {
      console.error('Error fetching leaderboard:', error);
    }
  };

  useEffect(() => {
    fetchLeaderboard();
    const intervalId = setInterval(fetchLeaderboard, 60000); // Обновление каждую минуту
    return () => clearInterval(intervalId);
  }, []);

  return (
    <LeaderboardContainer>
      <LeaderboardTitle>Рейтинг игроков</LeaderboardTitle>
      <LeaderboardList>
        {leaderboardData.map((player, index) => (
          <LeaderboardItem key={player.telegramId}>
          <div style={{ position: 'relative' }}>
            <PlayerAvatar src={player.photoUrl || NonAvatar} alt={player.username} />
            <LvlStarImg src={lvlStar}>
              <PlayerLevel>{player.level}</PlayerLevel>
            </LvlStarImg>
          </div>
          <PlayerInfo>
            <PlayerName>{player.username}<br/>({player.title || player.customTitle})</PlayerName>
          </PlayerInfo>
          <PlayerBalance>🪙 {player.balance}</PlayerBalance>
        </LeaderboardItem>
        ))}
      </LeaderboardList>
      <BackToFarmButton to="/">Вернуться на ферму</BackToFarmButton>
    </LeaderboardContainer>
  );
};

export default Leaderboard;