import { useCallback, useEffect, useRef } from 'react';

export function useAdsgram({ blockId, onReward, onError }) {
    const AdControllerRef = useRef(undefined);
    const tokenRef = useRef(null);
  
    useEffect(() => {
      const initAdsgram = async () => {
        console.log('Initializing Adsgram...');
        console.log('window.Adsgram:', window.Adsgram);
        console.log('window.ADSGRAM_USER_ID:', window.ADSGRAM_USER_ID);
        if (window.Adsgram && window.ADSGRAM_USER_ID) {
          console.log('Initializing Adsgram with blockId:', blockId);
          try {
            // Получаем токен с сервера
            const response = await fetch('/api/get-reward-token', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ userId: window.ADSGRAM_USER_ID }),
            });
            if (!response.ok) {
              throw new Error('Failed to get reward token');
            }
            const { token } = await response.json();
            tokenRef.current = token; // Сохраняем токен
            AdControllerRef.current = window.Adsgram.init({
              blockId,
              rewardUrl: `https://crptchrd.online/api/reward?token=${token}`
            });
            console.log('Adsgram initialized:', AdControllerRef.current);
          } catch (error) {
            console.error('Error initializing Adsgram:', error);
            onError?.(error);
          }
        } else {
          console.log('Adsgram or USER_ID not available');
        }
      };
      initAdsgram();
      const timeoutId = setTimeout(initAdsgram, 1000);
      return () => clearTimeout(timeoutId);
    }, [blockId, onError]);
  
    return useCallback(async () => {
      console.log('Trying to show ad...');
      if (AdControllerRef.current) {
        AdControllerRef.current
          .show()
          .then(() => {
            console.log('Ad shown successfully');
            // Используем сохраненный токен при вызове onReward
            onReward(tokenRef.current);
          })
          .catch((result) => {
            console.error('Error showing ad:', result);
            onError?.(result);
          });
      } else {
        console.error('Adsgram not initialized or user ID not set');
        onError?.({
          error: true,
          done: false,
          state: 'load',
          description: 'Adsgram not initialized or user ID not set',
        });
      }
    }, [onError, onReward]);
  }