import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import AnimatedSVGBackground from './AnimatedSVGBackground';

const BannedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
  text-align: center;
  position: relative;
  z-index: 1;
`;

const BannedMessage = styled.h1`
  font-size: 24px;
  color: #ff0000;
  margin-bottom: 20px;
`;

const BanReason = styled.p`
  font-size: 18px;
  color: #333;
  margin-bottom: 20px;
`;

const ContactInfo = styled.p`
  font-size: 16px;
  color: #666;
`;

const BannedUser = () => {
  const { banReason } = useSelector(state => state.user);

  return (
    <>
      <AnimatedSVGBackground />
      <BannedContainer>
        <BannedMessage>Your account has been banned</BannedMessage>
        <BanReason>Reason: {banReason || 'Violation of terms of service'}</BanReason>
        <ContactInfo>
          If you believe this is a mistake, please contact our support team at support@crptchrd.online
        </ContactInfo>
      </BannedContainer>
    </>
  );
};

export default BannedUser;