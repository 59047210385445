import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import NonAvatar from '../assets/nonavatarfarmer.jpeg';

const LeaderboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  min-height: 100vh;
  background-color: #f0f8ff;
`;

const LeaderboardTitle = styled.h2`
  font-size: 24px;
  margin-bottom: 20px;
`;

const LeaderboardList = styled.ul`
  list-style-type: none;
  padding: 0;
  width: 100%;
  max-width: 600px;
`;

const LeaderboardItem = styled.li`
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const PlayerAvatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
`;

const PlayerInfo = styled.div`
  flex-grow: 1;
`;

const PlayerName = styled.span`
  font-weight: bold;
`;

const PlayerLevel = styled.span`
  margin-left: 10px;
  color: #666;
`;

const PlayerBalance = styled.span`
  font-weight: bold;
  color: #4caf50;
`;

const BackToFarmButton = styled(Link)`
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  text-decoration: none;
  border-radius: 5px;
`;

const Leaderboard = () => {
  const [leaderboardData, setLeaderboardData] = useState([]);

  useEffect(() => {
    const fetchLeaderboard = async () => {
      try {
        const response = await fetch('/api/leaderboard');
        if (response.ok) {
          const data = await response.json();
          setLeaderboardData(data);
        } else {
          console.error('Failed to fetch leaderboard data');
        }
      } catch (error) {
        console.error('Error fetching leaderboard:', error);
      }
    };

    fetchLeaderboard();
  }, []);

  return (
    <LeaderboardContainer>
      <LeaderboardTitle>Рейтинг игроков</LeaderboardTitle>
      <LeaderboardList>
        {leaderboardData.map((player, index) => (
          <LeaderboardItem key={player.telegramId}>
            <PlayerAvatar src={player.photoUrl || `${NonAvatar}`} alt={player.username} />
            <PlayerInfo>
              <PlayerName>{player.username}<br/>({player.title || player.customTitle})</PlayerName>
              <PlayerLevel>Уровень: {player.level}</PlayerLevel>
            </PlayerInfo>
            <PlayerBalance>🪙 {player.balance}</PlayerBalance>
          </LeaderboardItem>
        ))}
      </LeaderboardList>
      <BackToFarmButton to="/">Вернуться на ферму</BackToFarmButton>
    </LeaderboardContainer>
  );
};

export default Leaderboard;