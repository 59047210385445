import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import Farm from './components/Farm';
import { GlobalStyle } from './components/Farm';
import Shop from './components/Shop';
import QuestsPage from './components/QuestsPage';
import AdminPanel from './components/AdminPanel';
import DepositPage from './components/DepositPage';
import Leaderboard from './components/Leaderboard';
import BottomMenu from './components/BottomMenu';
import { fetchFarm } from './farmSlice';
import { updateUser } from './userSlice';
import LoadingScreen from './components/LoadingScreen';
import UnsupportedPlatform from './components/UnsupportedPlatform';

function AppContent() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [isValidPlatform, setIsValidPlatform] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const currentPath = location.pathname;
    const tg = window.Telegram?.WebApp;

    // Проверка для админской панели
    if (currentPath === '/admin') {
      setIsLoading(false);
      setIsValidPlatform(true);
      return;
    }

    // Проверка наличия Telegram WebApp API и платформы
    if (tg && (tg.platform === 'ios' || tg.platform === 'android')) {
      setIsValidPlatform(true);
      const initData = tg.initData;
      console.log('InitData:', initData);

      if (initData) {
        localStorage.setItem('telegramInitData', initData);
        dispatch(fetchFarm(initData))
          .unwrap()
          .then((data) => {
            if (data && data.telegramId) {
              console.log('Updating user with telegramId:', data.telegramId);
              dispatch(updateUser({ telegramId: data.telegramId }));
            }
            setTimeout(() => setIsLoading(false), 3000);
          })
          .catch((error) => {
            console.error('Error fetching farm:', error);
            setTimeout(() => setIsLoading(false), 3000);
          });
      } else {
        setTimeout(() => setIsLoading(false), 3000);
      }
    } else {
      setIsValidPlatform(false);
      setIsLoading(false);
    }
  }, [dispatch, location]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!isValidPlatform && location.pathname !== '/admin') {
    return <UnsupportedPlatform />;
  }

  const showBottomMenu = location.pathname !== '/admin';

  return (
    <>
      <Routes>
        <Route path="/" element={<Farm />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/admin" element={<AdminPanel />} />
        <Route path="/deposit" element={<DepositPage />} />
        <Route path="/quests" element={<QuestsPage />} />
        <Route path="/leaderboard" element={<Leaderboard />} />
      </Routes>
      {showBottomMenu && <BottomMenu />}
    </>
  );
}

function App() {
  return (
    <>
      <GlobalStyle />
      <TonConnectUIProvider manifestUrl="https://crptchrd.online/tonconnect-manifest.json">
        <Router>
          <AppContent />
        </Router>
      </TonConnectUIProvider>
    </>
  );
}

export default App;